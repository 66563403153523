import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { ActivatedRoute, Router } from '@angular/router';
import {
  ClaimService,
  DashboardService,
  RecentClaim,
  RecentClaimNavigationType,
  SetClaimStatusStatusAction,
  StatusStatisticsItem,
  StatusStatisticsItemDisplayState,
} from '@claim-management-lib/data-access';
import {
  RecentClaimService,
  api_version,
} from '@claim-management-lib/feat-claim-shared';
import { DsPresetCalenderHeaderComponent } from '@design-system/components/advanced-datepicker';
import {
  DsSnackbarService,
  DsSnackbarType,
} from '@design-system/feature/snackbar';
import { TranslateService } from '@ngx-translate/core';
import { DateUtils } from '@paldesk/shared-lib/utils/date-utils';
import { filterTruthy } from '@shared-lib/rxjs';
import { PromptComponent } from '@ui-kit/prompt';
import { BehaviorSubject, Observable, filter, of, switchMap, take } from 'rxjs';
import { catchError, finalize, map, tap } from 'rxjs/operators';
import { NotificationSettingsComponent } from './notification-settings/notification-settings.component';

export interface ChartData {
  name: string;
  value: number;
  extra?: any;
}

export enum LegendPosition {
  Right = 'right',
  Below = 'below',
}

@Component({
  selector: 'cm-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
  protected readonly SetClaimStatusStatusAction = SetClaimStatusStatusAction;
  chartData$: Observable<ChartData[] | undefined>;
  chartDataArea$: Observable<ChartData[] | undefined>;
  recentClaims$: BehaviorSubject<RecentClaim[] | undefined> =
    new BehaviorSubject<RecentClaim[] | undefined>(undefined);
  filteredClaims: RecentClaim[];
  chartLoading = false;

  view: [number, number] = [280, 300];
  legendPosition = LegendPosition.Below;

  colorScheme = {
    domain: [
      '#758087',
      '#ff5864',
      '#41b563',
      '#3c88ec',
      '#f3b980',
      '#4b575f',
      '#d9585e',
      '#3c88ec',
    ],
  };

  translationKeyPrefix = 'claim-management.dashboard.';

  dateRange: FormGroup;
  today = new Date();

  DsPresetCalenderHeaderComponent = DsPresetCalenderHeaderComponent;

  constructor(
    private claimService: ClaimService,
    private dashboardService: DashboardService,
    private translateService: TranslateService,
    private fb: FormBuilder,
    private dateAdapter: DateAdapter<any>,
    private snackbar: DsSnackbarService,
    private router: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private recentClaimService: RecentClaimService,
  ) {}

  ngOnInit(): void {
    this.route.fragment
      .pipe(
        filter((fragment) => fragment === 'notification_settings'),
        take(1),
      )
      .subscribe(() => {
        this.dialog.open(NotificationSettingsComponent, {
          width: '600px',
          disableClose: true,
        });
      });

    this.dateRange = this.fb.group({
      from: [this.dateAdapter.addCalendarDays(this.today, -89)],
      to: [this.today],
    });

    this.claimService
      .getRecentClaims(api_version)
      .pipe(
        map((data) => data.recent_claims),
        filterTruthy(),
        tap((data) => {
          this.filterClaims(
            {
              pageIndex: 0,
              length: data?.length || 0,
              pageSize: 5,
            },
            data,
          );
        }),
        catchError(() => this.handleError()),
      )
      .subscribe((recentClaims) => {
        this.recentClaims$.next(recentClaims);
      });

    this.getClaims();
  }

  handleError(): Observable<undefined> {
    this.snackbar.queue(
      this.translateService.instant('general.error_code.error', {
        type: DsSnackbarType.Error,
      }),
    );
    return of(undefined);
  }

  getClaims() {
    this.chartLoading = true;
    this.chartData$ = of(undefined);
    this.chartDataArea$ = of(undefined);

    const from = this.dateRange.value.from;
    const to = this.dateRange.value.to;

    this.dashboardService
      .getStatusStatistics(
        api_version,
        from ? new Date(DateUtils.toISODateStringLocal(from)) : undefined,
        to ? new Date(DateUtils.toISODateStringLocal(to)) : undefined,
      )
      .pipe(finalize(() => (this.chartLoading = false)))
      .subscribe({
        next: (data) => {
          this.setChartDataArea(data.statuses_ga);
          this.setChartData(data.statuses_sp);
        },
        error: () => this.handleError(),
      });
  }

  filterClaims(pageChange: PageEvent, claims?: RecentClaim[]): void {
    this.filteredClaims =
      claims?.slice(
        pageChange.pageIndex * pageChange.pageSize,
        (pageChange.pageIndex + 1) * pageChange.pageSize,
      ) || [];
  }

  getClaimLinkByNavigationType(
    navigation_type: RecentClaimNavigationType,
  ): string {
    switch (navigation_type) {
      case RecentClaimNavigationType.Decision:
        return '/decision/';
      case RecentClaimNavigationType.Delivery:
        return '/delivery/';
      case RecentClaimNavigationType.Edit:
        return '/claim/edit/';
      case RecentClaimNavigationType.DecisionDelivery:
        return '/delivery/decision/';
      default:
        return '';
    }
  }

  goToClaimsByStatus(event, statusForDecisionServicePartner: boolean) {
    if (!event.extra) {
      return;
    }
    this.router.navigate(['/claimdesk', 'custom'], {
      queryParams: {
        customFilter: 'ByStatusState',
        statusState: event.extra,
        statusForDecisionServicePartner,
        start_date: `date(${DateUtils.toISODateStringLocal(
          this.dateRange.controls['from'].value,
        )})`,
        end_date: `date(${DateUtils.toISODateStringLocal(
          this.dateRange.controls['to'].value,
        )})`,
      },
    });
  }

  markAllClaimsAsSeen(): void {
    const promptRef = this.dialog.open(PromptComponent);
    promptRef.componentInstance.title = this.translateService.instant(
      'claim-management.dashboard.mark_all_as_seen',
    );
    promptRef.componentInstance.content = this.translateService.instant(
      'claim-management.dashboard.mark_all_as_seen_confirm',
    );
    promptRef
      .afterClosed()
      .pipe(
        filter((x) => !!x),
        switchMap(() =>
          this.recentClaimService.markAllClaimsAsSeenObservable(),
        ),
        catchError(() => this.handleError()),
      )
      .subscribe(() => {
        this.filteredClaims = [];
        this.recentClaims$.next([]);
      });
  }

  private setChartData(claims?: StatusStatisticsItem[]): void {
    if (!claims) {
      return;
    }
    const recentStates: StatusStatisticsItemDisplayState[] = [
      StatusStatisticsItemDisplayState.Returned,
      StatusStatisticsItemDisplayState.PartsRequested,
      StatusStatisticsItemDisplayState.ReceivedFromSP,
      StatusStatisticsItemDisplayState.AcceptedFully,
      StatusStatisticsItemDisplayState.AcceptedPartially,
      StatusStatisticsItemDisplayState.Declined,
      StatusStatisticsItemDisplayState.PartsSentFromSP,
      StatusStatisticsItemDisplayState.InformationGivenToGA,
    ];

    this.chartData$ = this.mapToChartData(recentStates, claims);
  }

  private setChartDataArea(claims?: StatusStatisticsItem[]): void {
    if (!claims) {
      return;
    }

    const recentStatesAREA: StatusStatisticsItemDisplayState[] = [
      StatusStatisticsItemDisplayState.Returned,
      StatusStatisticsItemDisplayState.AcceptedFully,
      StatusStatisticsItemDisplayState.PartsRequested,
      StatusStatisticsItemDisplayState.AcceptedPartially,
      StatusStatisticsItemDisplayState.Declined,
    ];

    this.chartDataArea$ = this.mapToChartData(recentStatesAREA, claims);
  }

  private mapToChartData(
    states: StatusStatisticsItemDisplayState[],
    claims: StatusStatisticsItem[],
  ): Observable<ChartData[]> {
    return this.translateService
      .get(states.map((state) => this.translationKeyPrefix + state))
      .pipe(
        map((translations) =>
          states
            .map((state) => ({
              name: translations[this.translationKeyPrefix + state],
              value:
                claims.find((cl) => cl.display_state === state)?.claim_count ||
                0,
              extra: state,
            }))
            .filter((x) => x.value > 0),
        ),
      );
  }
}
