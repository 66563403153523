import {
  DisabledPositionType,
  TableSettings,
} from '@design-system/components/table-settings';

export const settingsKey = 'cm.action-log';

export const decisionGaColumns: TableSettings[] = [
  {
    name: 'modified_by',
    selected: true,
  },
  {
    name: 'modified_on',
    selected: true,
  },
  {
    name: 'state_sp_to_ga',
    selected: true,
  },
  {
    name: 'state_area_ga',
    selected: true,
  },
  {
    name: 'decision_reason',
    selected: true,
  },
  {
    name: 'return_reason',
    selected: true,
  },
  {
    name: 'actions',
    selected: true,
    disabledPosition: DisabledPositionType.End,
  },
];

export const simpleColumns: TableSettings[] = [
  {
    name: 'modified_by',
    selected: true,
  },
  {
    name: 'modified_on',
    selected: true,
  },
  {
    name: 'state',
    selected: true,
  },
  {
    name: 'actions',
    selected: true,
    disabledPosition: DisabledPositionType.End,
  },
];
