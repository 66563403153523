import { HttpEvent, HttpEventType } from '@angular/common/http';
import {
  Attachment,
  AttachmentAttachmentType,
  UploadFileResponse,
} from '@claim-management-lib/data-access';
import { GuidUtils } from '@utils/guid-utils';
import { AttachmentFile } from '../claim-management-service.model';

export class AttachmentProcessor {
  public static GetAttachmentType(fileType: string): AttachmentAttachmentType {
    if (fileType.indexOf('image/') >= 0) {
      return AttachmentAttachmentType.Image;
    } else if (fileType.indexOf('video/') >= 0) {
      return AttachmentAttachmentType.Video;
    } else {
      return AttachmentAttachmentType.Attachment;
    }
  }

  public static ProcessUpload(
    attachedFile: AttachmentFile,
    uploadEvent: HttpEvent<UploadFileResponse>,
  ): void {
    if (uploadEvent.type === HttpEventType.Response) {
      attachedFile.url = uploadEvent.body?.file_url || '';
      attachedFile.thumbnailUrl = uploadEvent.body?.thumb_url;
      attachedFile.progress = 100;
    } else if (uploadEvent.type === HttpEventType.UploadProgress) {
      attachedFile.progress = Math.round(
        (uploadEvent.loaded * 100) / (uploadEvent.total || 1),
      );
    }
  }

  public static MapToAttachment(
    attachments: AttachmentFile[] = [],
  ): Attachment[] {
    return attachments?.map((x) => ({
      rotation_angle: x.rotationAngle,
      file_name: x.name,
      file_uri: x.url,
      attachment_type: x.attachment_type,
      attachment_id:
        isNaN(Number(x.id)) || Number(x.id) === 0 ? undefined : Number(x.id),
      thumbnail_uri: x.thumbnailUrl,
      is_visible_to_sp: x.is_visible_to_sp,
      belong_to_sp: x.belong_to_sp,
      deleted: x.deleted,
    }));
  }

  public static MapToAttachmentFile(
    attachments: Attachment[] = [],
  ): AttachmentFile[] {
    return attachments.map((f) => ({
      progress: 100,
      url: f.file_uri || '',
      size: 0, //BE is currently not storing file size
      name: f.file_name,
      id: f.attachment_id?.toString() || GuidUtils.newGuid(),
      attachment_type: f.attachment_type,
      rotationAngle: f.rotation_angle,
      is_visible_to_sp: f.is_visible_to_sp,
      thumbnailUrl: f.thumbnail_uri,
      deleted: false,
    }));
  }
}
