import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  ClaimService,
  GetClaimNavigationTypeResponseNavigationType,
} from '@claim-management-lib/data-access';
import { api_version } from '../constants';

@Injectable({ providedIn: 'root' })
export class LinkService {
  constructor(
    private claimService: ClaimService,
    private router: Router,
  ) {}

  public resolveLink(claimId: string): void {
    this.claimService
      .getClaimNavigationType(Number(claimId), api_version)
      .subscribe({
        next: (navigationType) => {
          switch (navigationType.navigation_type) {
            case GetClaimNavigationTypeResponseNavigationType.Decision:
              this.router.navigateByUrl('decision/' + claimId);
              break;
            case GetClaimNavigationTypeResponseNavigationType.Edit:
              this.router.navigateByUrl('claim/edit/' + claimId);
              break;
            case GetClaimNavigationTypeResponseNavigationType.Delivery:
              this.router.navigateByUrl('delivery/' + claimId);
              break;
            case GetClaimNavigationTypeResponseNavigationType.DecisionDelivery:
              this.router.navigateByUrl('delivery/decision/' + claimId);
              break;
            default:
              this.router.navigateByUrl('special');
              break;
          }
        },
        error: () => {
          this.router.navigateByUrl('special');
        },
      });
  }
}
