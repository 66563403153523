import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { APPS } from '@config';
import { UserService } from '@features/auth';
import { filterTruthy } from '@shared-lib/rxjs';
import { distinctUntilChanged, map, Observable } from 'rxjs';

@Injectable()
export class AppGuard {
  constructor(
    public router: Router,
    private userService: UserService,
  ) {}

  canActivate(): Observable<boolean | UrlTree> {
    return this.userService.isAuthorized$.pipe(
      distinctUntilChanged(),
      filterTruthy(),
      map(() =>
        this.userService.hasRole(APPS.ECLAIM.ROLES.RECALL_ADMIN)
          ? this.router.parseUrl('/ram')
          : true,
      ),
    );
  }
}
