<table
  *ngIf="isGermanContract; else nonGermanContract"
  class="contract-terms-table"
>
  <thead>
    <tr>
      <th>
        {{ 'contract_terms.contract_type' | translate }}
      </th>
      <th>{{ 'contract_terms.basic' | translate }}</th>
      <th>{{ 'contract_terms.complete' | translate }}</th>
      <th>{{ 'contract_terms.comfort' | translate }}</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>
        <strong>{{ 'contract_terms.legal_inspection' | translate }}</strong>
      </td>
      <td class="text-center"><mat-icon>check</mat-icon></td>
      <td class="text-center"><mat-icon>check</mat-icon></td>
      <td class="text-center"><mat-icon>check</mat-icon></td>
    </tr>
    <tr>
      <td>
        {{ 'contract_terms.periodic_service' | translate }}
      </td>
      <td class="text-center"><mat-icon>check</mat-icon></td>
      <td class="text-center"><mat-icon>check</mat-icon></td>
      <td class="text-center"><mat-icon>check</mat-icon></td>
    </tr>
    <tr>
      <td>
        {{ 'contract_terms.repair_service' | translate }}
      </td>
      <td></td>
      <td class="text-center"><mat-icon>check</mat-icon></td>
      <td class="text-center"><mat-icon>check</mat-icon></td>
    </tr>
    <tr>
      <td>
        {{ 'contract_terms.wear_parts_service' | translate }}
      </td>
      <td></td>
      <td class="text-center"><mat-icon>check</mat-icon></td>
      <td class="text-center"><mat-icon>check</mat-icon></td>
    </tr>
    <tr>
      <td>
        <strong>{{ 'contract_terms.maintenance' | translate }}</strong>
      </td>
      <td></td>
      <td></td>
      <td class="text-center"><mat-icon>check</mat-icon></td>
    </tr>
    <tr>
      <td>
        <strong>{{ 'contract_terms.cleaning' | translate }}</strong>
      </td>
      <td></td>
      <td></td>
      <td class="text-center"><mat-icon>check</mat-icon></td>
    </tr>
  </tbody>
</table>
<ng-template #nonGermanContract>
  <table class="contract-terms-table">
    <thead>
      <tr>
        <th>
          {{ 'contract_terms.contract_type' | translate }}
        </th>
        <th>{{ 'contract_terms.pure' | translate }}</th>
        <th>
          {{ 'contract_terms.performance' | translate }}
        </th>
        <th>{{ 'contract_terms.pro' | translate }}</th>
        <th>{{ 'contract_terms.prime' | translate }}</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>
          <strong>{{ 'contract_terms.oil_maintenance' | translate }}</strong>
        </td>
        <td class="text-center"><mat-icon>check</mat-icon></td>
        <td class="text-center"><mat-icon>check</mat-icon></td>
        <td class="text-center"><mat-icon>check</mat-icon></td>
        <td class="text-center"><mat-icon>check</mat-icon></td>
      </tr>
      <tr>
        <td>
          {{ 'contract_terms.yearly_filter_change' | translate }}
        </td>
        <td class="text-center"><mat-icon>check</mat-icon></td>
        <td class="text-center"><mat-icon>check</mat-icon></td>
        <td class="text-center"><mat-icon>check</mat-icon></td>
        <td class="text-center"><mat-icon>check</mat-icon></td>
      </tr>
      <tr>
        <td>
          {{ 'contract_terms.yearly_oil_check' | translate }}
        </td>
        <td class="text-center"><mat-icon>check</mat-icon></td>
        <td class="text-center"><mat-icon>check</mat-icon></td>
        <td class="text-center"><mat-icon>check</mat-icon></td>
        <td class="text-center"><mat-icon>check</mat-icon></td>
      </tr>
      <tr>
        <td>
          {{ 'contract_terms.oil_change' | translate }}
        </td>
        <td class="text-center"><mat-icon>check</mat-icon></td>
        <td class="text-center"><mat-icon>check</mat-icon></td>
        <td class="text-center"><mat-icon>check</mat-icon></td>
        <td class="text-center"><mat-icon>check</mat-icon></td>
      </tr>
      <tr>
        <td>
          <strong>{{ 'contract_terms.periodic_service' | translate }}</strong>
        </td>
        <td></td>
        <td class="text-center"><mat-icon>check</mat-icon></td>
        <td class="text-center"><mat-icon>check</mat-icon></td>
        <td class="text-center"><mat-icon>check</mat-icon></td>
      </tr>
      <tr>
        <td>
          <strong>{{ 'contract_terms.legal_inspection' | translate }}</strong>
        </td>
        <td></td>
        <td class="text-center"><mat-icon>check</mat-icon></td>
        <td class="text-center"><mat-icon>check</mat-icon></td>
        <td class="text-center"><mat-icon>check</mat-icon></td>
      </tr>
      <tr>
        <td>
          <strong>{{ 'contract_terms.repair_service' | translate }}</strong>
          -
          {{ 'contract_terms.labor_and_material_included' | translate }}
        </td>
        <td></td>
        <td></td>
        <td class="text-center"><mat-icon>check</mat-icon></td>
        <td class="text-center"><mat-icon>check</mat-icon></td>
      </tr>
      <tr>
        <td>
          <strong>{{ 'contract_terms.wear_parts_service' | translate }}</strong>
          -
          {{ 'contract_terms.labor_and_material_included' | translate }}
        </td>
        <td></td>
        <td></td>
        <td></td>
        <td class="text-center"><mat-icon>check</mat-icon></td>
      </tr>
      <tr>
        <td>
          <strong>{{
            'contract_terms.monthly_maintenance' | translate
          }}</strong>
          -
          {{ 'contract_terms.labor_and_material_included' | translate }}
        </td>
        <td></td>
        <td></td>
        <td></td>
        <td class="text-center">
          {{ 'contract_terms.optional' | translate }}
        </td>
      </tr>
    </tbody>
  </table>
</ng-template>
