import { NgModule } from '@angular/core';
import { Route, RouterModule } from '@angular/router';
import { SharedModule } from '@claim-management-lib/feat-claim-shared';
import { DsAdvancedDatepickerModule } from '@design-system/components/advanced-datepicker';
import { TableSettingsIntl } from '@design-system/components/table-settings';
import { DsPageModule } from '@design-system/feature/page';
import { TranslateService } from '@ngx-translate/core';
import { ClaimListComponent } from './claim-list/claim-list.component';
import { TableSettingsI18n } from './shared/i18n';

export const claimdeskRoutes: Route[] = [
  { path: '', pathMatch: 'full', redirectTo: 'all' },
  {
    path: ':id',
    component: ClaimListComponent,
  },
];

function getTableSettingsIntl(translateService: TranslateService) {
  return new TableSettingsI18n(translateService).getIntl();
}

@NgModule({
  declarations: [ClaimListComponent],
  imports: [
    RouterModule.forChild(claimdeskRoutes),
    SharedModule,
    DsPageModule,
    DsAdvancedDatepickerModule,
  ],
  providers: [
    {
      provide: TableSettingsIntl,
      deps: [TranslateService],
      useFactory: getTableSettingsIntl,
    },
  ],
})
export class ClaimdeskModule {}
