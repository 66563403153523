import { ChangeDetectorRef } from '@angular/core';
import {
  AbstractControl,
  AsyncValidatorFn,
  ValidationErrors,
} from '@angular/forms';
import {
  CheckDeliveryNoteResponseCheckResult,
  DeliveryService,
} from '@claim-management-lib/data-access';
import { Observable, of } from 'rxjs';
import { catchError, finalize, map } from 'rxjs/operators';
import { api_version } from '../constants';

export function DeliveryNoteValidator(
  deliveryService: DeliveryService,
  cd: ChangeDetectorRef,
): AsyncValidatorFn {
  return (
    ctrl: AbstractControl,
  ): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> =>
    deliveryService.checkDeliveryNote(ctrl.value.trim(), api_version).pipe(
      map((check) =>
        check.check_result === CheckDeliveryNoteResponseCheckResult.Valid
          ? null
          : { numberIsInvalid: true },
      ),
      catchError(() => of({ numberIsInvalid: true })),
      finalize(() => {
        cd.markForCheck();
      }),
    );
}
