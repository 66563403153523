/**
 * Outputs a number using toFixed() javascript method
 * @param value Value to be transformed.
 * @example preciseFloatingPointNumber(0.3 + 0.6); // 0.9
 */
export function preciseFloatingPointNumber(value: number): number {
  //only process numbers
  if (!value && value !== 0) return value;
  let decimal = countDecimals(value);
  if (decimal >= 6) {
    decimal = decimal - 1;
  } else {
    return value;
  }
  return parseFloat(value.toFixed(decimal));
}
export function countDecimals(value: number): number {
  if (Math.floor(value) === value) return 0;
  return value.toString().split('.')[1].length || 0;
}
