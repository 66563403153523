import { ChangeDetectorRef } from '@angular/core';
import {
  AbstractControl,
  AsyncValidatorFn,
  ValidationErrors,
} from '@angular/forms';
import {
  EquipmentService,
  ValidateEquipmentNumberResponseValidationResult,
} from '@claim-management-lib/data-access';
import { Observable, of } from 'rxjs';
import { catchError, finalize, map } from 'rxjs/operators';
import { api_version } from '../constants';

export function EquipmentNumberValidator(
  equipmentService: EquipmentService,
  cd: ChangeDetectorRef,
): AsyncValidatorFn {
  return (
    ctrl: AbstractControl,
  ): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> => {
    if (!ctrl.value) {
      return of(null);
    }
    return equipmentService
      .validateEquipmentNumber(ctrl.value.trim(), api_version)
      .pipe(
        map((check) =>
          check.validation_result ===
          ValidateEquipmentNumberResponseValidationResult.Valid
            ? null
            : { numberIsInvalid: true },
        ),
        catchError(() => of({ numberIsInvalid: true })),
        finalize(() => cd.markForCheck()),
      );
  };
}
