import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ClaimService, PalcodeDetail } from '@claim-management-lib/data-access';
import {
  Observable,
  debounceTime,
  filter,
  finalize,
  map,
  switchMap,
} from 'rxjs';
import { api_version } from '../../constants';

@Component({
  selector: 'paldesk-palcode-check',
  templateUrl: './palcode-check.component.html',
  styleUrls: ['./palcode-check.component.scss'],
})
export class PalcodeCheckComponent implements OnInit {
  code: FormControl;
  searchResults$: Observable<PalcodeDetail[]>;
  searchPending = false;
  dataSource$: Observable<MatTableDataSource<PalcodeDetail>>;
  paginator: MatPaginator;
  @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
    if (mp) {
      this.paginator = mp;
    }
  }

  constructor(private claimService: ClaimService) {}

  ngOnInit(): void {
    this.code = new FormControl();
    this.dataSource$ = this.code.valueChanges.pipe(
      debounceTime(400),
      filter((v) => !!v),
      switchMap((val) => {
        this.searchPending = true;
        return this.claimService.getPalcodes(val, api_version).pipe(
          map((r) => {
            const dataSource = new MatTableDataSource(r.codes);
            dataSource.paginator = this.paginator;
            return dataSource;
          }),
          finalize(() => (this.searchPending = false)),
        );
      }),
    );
  }
}
