<ds-app-wrapper
  [menu]="menu"
  [menuLoadingCount]="loadingNav ? 3 : 0"
  [disablePageHeadlines]="true"
>
  <ds-breadcrumbs
    baseUrl="/"
    baseLabel="{{ 'Claim Management' }}"
    gaCategoryGroup="App"
    gaClickListener="BreadcrumbsClick"
  >
  </ds-breadcrumbs>
  <div fxFlex="1 1 auto" class="flex-page-container">
    <router-outlet></router-outlet>
  </div>
</ds-app-wrapper>
