<mat-form-field class="full-width">
  <mat-label>
    {{ labelKey | translate }}
    <ng-container *ngIf="required">*</ng-container>
  </mat-label>
  <mat-chip-grid #chipList>
    <mat-chip-row *ngFor="let email of nonRemovableEmails">{{
      email
    }}</mat-chip-row>
    <mat-chip-row
      *ngFor="let email of emailList"
      (removed)="removeEmail(email)"
    >
      {{ email }}
      <button matChipRemove>
        <mat-icon>cancel</mat-icon>
      </button>
    </mat-chip-row>
    <input
      placeholder="{{ 'email_addresses.add_email' | translate }}"
      [disabled]="disabled"
      [matChipInputFor]="chipList"
      [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
      [matChipInputAddOnBlur]="true"
      (matChipInputTokenEnd)="addEmail($event)"
  /></mat-chip-grid>
  <mat-hint>{{
    'email_addresses.email_addresses_tooltip' | translate
  }}</mat-hint>

  <mat-hint align="end"
    ><ng-content select="[emailAddressesHint]"></ng-content
  ></mat-hint>

  <mat-error>
    {{ 'email_addresses.invalid_email' | translate }}
  </mat-error>
</mat-form-field>
