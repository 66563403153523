import { AttachmentAttachmentType } from '@claim-management-lib/data-access';
import { AttachedFile } from '@design-system/cdk/file-drop';

export const attachmentsSupportedFiles: string[] = [
  'jpg',
  'jpeg',
  'png',
  'gif',
  'doc',
  'docx',
  'xls',
  'xlsx',
  'ppt',
  'pptx',
  'pdf',
  'psy',
  'rrc',
  'p50',
  'p100',
  'p40',
  'p150',
  'pal',
  'pwp',
  'ppr',
  'dxf',
  'zip',
  'rar',
  'mp4',
  'pecu',
  'prrc',
  'psys',
  'ars',
  'msg',
  'mov',
  'txt',
  'hevc',
  'heic',
  'heif',
];

export const attachmentsMaintenanceSupportedFiles: string[] = ['xlsx'];

export interface AttachmentFile extends AttachedFile {
  attachment_type: AttachmentAttachmentType;
  is_visible_to_sp?: boolean;
  belong_to_sp?: boolean;
  deleted: boolean;
}
