/**
 * v1.1 - Claim Management API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1_claim_management
 * Contact: F.John@palfinger.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec
        }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

// @ts-ignore
import { GetClaimdeskOptionsResponse } from '../model/getClaimdeskOptionsResponse';
// @ts-ignore
import { GetClaimdeskSummary } from '../model/getClaimdeskSummary';
// @ts-ignore
import { ListDeskClaimsResponse } from '../model/listDeskClaimsResponse';

// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';



@Injectable({
  providedIn: 'root'
})
export class ClaimDeskService {

    protected basePath = 'provide_injection_token_for_ClaimDeskService';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    // @ts-ignore
    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key, (value as Date).toISOString().substr(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * @param apiVersion The requested API version
     * @param sortConcatenation 
     * @param statusState 
     * @param statusForDecisionServicePartner 
     * @param productLineEquipmentType 
     * @param periodDayDiffFrom 
     * @param periodDayDiffTo 
     * @param periodFilterType 
     * @param acceptanceRatioFilterType 
     * @param acceptanceRatioTableType 
     * @param acceptanceRatioPartnerType 
     * @param userFilterClaimNumber 
     * @param userFilterEquipmentNumber 
     * @param userFilterDeliveryNote 
     * @param userFilterClaimStates 
     * @param userFilterEquipmentType 
     * @param userFilterDateType 
     * @param userFilterServicePartnerId 
     * @param userFilterOnlyGaClaims 
     * @param userFilterClaimTypes 
     * @param userFilterInternalNote 
     * @param startDate 
     * @param endDate 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public exportDeskClaims(apiVersion: '1.1', sortConcatenation?: string, statusState?: 'Draft' | 'Sent' | 'Returned' | 'PartsRequested' | 'ReceivedFromSP' | 'PartsRequestedFromSP' | 'AcceptedFully' | 'AcceptedPartially' | 'Declined' | 'DecidedToSP' | 'PartsSentFromSP' | 'InformationRequestedFromSP' | 'InformationGivenToGA', statusForDecisionServicePartner?: boolean, productLineEquipmentType?: string, periodDayDiffFrom?: number, periodDayDiffTo?: number, periodFilterType?: 'ReturnedSentGroups' | 'RepairedSentGroups', acceptanceRatioFilterType?: 'Accepted' | 'NotAccepted' | 'DeclinedUnknownReason' | 'DeclinedMissingProductionData' | 'DeclinedIncompleteBasicData' | 'DeclinedMissingDataFile' | 'DeclinedSubmissionAfterDeadline' | 'DeclinedMaterialIssue' | 'DeclinedAttachmentMissing' | 'DeclinedTextAndCodingNotIdentical' | 'DeclinedOther', acceptanceRatioTableType?: 'Total' | 'HandlingLumpSum', acceptanceRatioPartnerType?: 'ServicePartner' | 'GeneralAgent', userFilterClaimNumber?: string, userFilterEquipmentNumber?: string, userFilterDeliveryNote?: string, userFilterClaimStates?: string, userFilterEquipmentType?: string, userFilterDateType?: 'NoDate' | 'Repair' | 'Create', userFilterServicePartnerId?: number, userFilterOnlyGaClaims?: boolean, userFilterClaimTypes?: string, userFilterInternalNote?: string, startDate?: string, endDate?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json',}): Observable<Blob>;
    public exportDeskClaims(apiVersion: '1.1', sortConcatenation?: string, statusState?: 'Draft' | 'Sent' | 'Returned' | 'PartsRequested' | 'ReceivedFromSP' | 'PartsRequestedFromSP' | 'AcceptedFully' | 'AcceptedPartially' | 'Declined' | 'DecidedToSP' | 'PartsSentFromSP' | 'InformationRequestedFromSP' | 'InformationGivenToGA', statusForDecisionServicePartner?: boolean, productLineEquipmentType?: string, periodDayDiffFrom?: number, periodDayDiffTo?: number, periodFilterType?: 'ReturnedSentGroups' | 'RepairedSentGroups', acceptanceRatioFilterType?: 'Accepted' | 'NotAccepted' | 'DeclinedUnknownReason' | 'DeclinedMissingProductionData' | 'DeclinedIncompleteBasicData' | 'DeclinedMissingDataFile' | 'DeclinedSubmissionAfterDeadline' | 'DeclinedMaterialIssue' | 'DeclinedAttachmentMissing' | 'DeclinedTextAndCodingNotIdentical' | 'DeclinedOther', acceptanceRatioTableType?: 'Total' | 'HandlingLumpSum', acceptanceRatioPartnerType?: 'ServicePartner' | 'GeneralAgent', userFilterClaimNumber?: string, userFilterEquipmentNumber?: string, userFilterDeliveryNote?: string, userFilterClaimStates?: string, userFilterEquipmentType?: string, userFilterDateType?: 'NoDate' | 'Repair' | 'Create', userFilterServicePartnerId?: number, userFilterOnlyGaClaims?: boolean, userFilterClaimTypes?: string, userFilterInternalNote?: string, startDate?: string, endDate?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json',}): Observable<HttpResponse<Blob>>;
    public exportDeskClaims(apiVersion: '1.1', sortConcatenation?: string, statusState?: 'Draft' | 'Sent' | 'Returned' | 'PartsRequested' | 'ReceivedFromSP' | 'PartsRequestedFromSP' | 'AcceptedFully' | 'AcceptedPartially' | 'Declined' | 'DecidedToSP' | 'PartsSentFromSP' | 'InformationRequestedFromSP' | 'InformationGivenToGA', statusForDecisionServicePartner?: boolean, productLineEquipmentType?: string, periodDayDiffFrom?: number, periodDayDiffTo?: number, periodFilterType?: 'ReturnedSentGroups' | 'RepairedSentGroups', acceptanceRatioFilterType?: 'Accepted' | 'NotAccepted' | 'DeclinedUnknownReason' | 'DeclinedMissingProductionData' | 'DeclinedIncompleteBasicData' | 'DeclinedMissingDataFile' | 'DeclinedSubmissionAfterDeadline' | 'DeclinedMaterialIssue' | 'DeclinedAttachmentMissing' | 'DeclinedTextAndCodingNotIdentical' | 'DeclinedOther', acceptanceRatioTableType?: 'Total' | 'HandlingLumpSum', acceptanceRatioPartnerType?: 'ServicePartner' | 'GeneralAgent', userFilterClaimNumber?: string, userFilterEquipmentNumber?: string, userFilterDeliveryNote?: string, userFilterClaimStates?: string, userFilterEquipmentType?: string, userFilterDateType?: 'NoDate' | 'Repair' | 'Create', userFilterServicePartnerId?: number, userFilterOnlyGaClaims?: boolean, userFilterClaimTypes?: string, userFilterInternalNote?: string, startDate?: string, endDate?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json',}): Observable<HttpEvent<Blob>>;
    public exportDeskClaims(apiVersion: '1.1', sortConcatenation?: string, statusState?: 'Draft' | 'Sent' | 'Returned' | 'PartsRequested' | 'ReceivedFromSP' | 'PartsRequestedFromSP' | 'AcceptedFully' | 'AcceptedPartially' | 'Declined' | 'DecidedToSP' | 'PartsSentFromSP' | 'InformationRequestedFromSP' | 'InformationGivenToGA', statusForDecisionServicePartner?: boolean, productLineEquipmentType?: string, periodDayDiffFrom?: number, periodDayDiffTo?: number, periodFilterType?: 'ReturnedSentGroups' | 'RepairedSentGroups', acceptanceRatioFilterType?: 'Accepted' | 'NotAccepted' | 'DeclinedUnknownReason' | 'DeclinedMissingProductionData' | 'DeclinedIncompleteBasicData' | 'DeclinedMissingDataFile' | 'DeclinedSubmissionAfterDeadline' | 'DeclinedMaterialIssue' | 'DeclinedAttachmentMissing' | 'DeclinedTextAndCodingNotIdentical' | 'DeclinedOther', acceptanceRatioTableType?: 'Total' | 'HandlingLumpSum', acceptanceRatioPartnerType?: 'ServicePartner' | 'GeneralAgent', userFilterClaimNumber?: string, userFilterEquipmentNumber?: string, userFilterDeliveryNote?: string, userFilterClaimStates?: string, userFilterEquipmentType?: string, userFilterDateType?: 'NoDate' | 'Repair' | 'Create', userFilterServicePartnerId?: number, userFilterOnlyGaClaims?: boolean, userFilterClaimTypes?: string, userFilterInternalNote?: string, startDate?: string, endDate?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json',}): Observable<any> {
        if (apiVersion === null || apiVersion === undefined) {
            throw new Error('Required parameter apiVersion was null or undefined when calling exportDeskClaims.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (sortConcatenation !== undefined && sortConcatenation !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sortConcatenation, 'sort_concatenation');
        }
        if (statusState !== undefined && statusState !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>statusState, 'status.State');
        }
        if (statusForDecisionServicePartner !== undefined && statusForDecisionServicePartner !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>statusForDecisionServicePartner, 'status.ForDecisionServicePartner');
        }
        if (productLineEquipmentType !== undefined && productLineEquipmentType !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>productLineEquipmentType, 'product_line.EquipmentType');
        }
        if (periodDayDiffFrom !== undefined && periodDayDiffFrom !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>periodDayDiffFrom, 'period.DayDiffFrom');
        }
        if (periodDayDiffTo !== undefined && periodDayDiffTo !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>periodDayDiffTo, 'period.DayDiffTo');
        }
        if (periodFilterType !== undefined && periodFilterType !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>periodFilterType, 'period.FilterType');
        }
        if (acceptanceRatioFilterType !== undefined && acceptanceRatioFilterType !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>acceptanceRatioFilterType, 'acceptance_ratio.FilterType');
        }
        if (acceptanceRatioTableType !== undefined && acceptanceRatioTableType !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>acceptanceRatioTableType, 'acceptance_ratio.TableType');
        }
        if (acceptanceRatioPartnerType !== undefined && acceptanceRatioPartnerType !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>acceptanceRatioPartnerType, 'acceptance_ratio.PartnerType');
        }
        if (userFilterClaimNumber !== undefined && userFilterClaimNumber !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>userFilterClaimNumber, 'user_filter.claim_number');
        }
        if (userFilterEquipmentNumber !== undefined && userFilterEquipmentNumber !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>userFilterEquipmentNumber, 'user_filter.equipment_number');
        }
        if (userFilterDeliveryNote !== undefined && userFilterDeliveryNote !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>userFilterDeliveryNote, 'user_filter.delivery_note');
        }
        if (userFilterClaimStates !== undefined && userFilterClaimStates !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>userFilterClaimStates, 'user_filter.claim_states');
        }
        if (userFilterEquipmentType !== undefined && userFilterEquipmentType !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>userFilterEquipmentType, 'user_filter.equipment_type');
        }
        if (userFilterDateType !== undefined && userFilterDateType !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>userFilterDateType, 'user_filter.date_type');
        }
        if (userFilterServicePartnerId !== undefined && userFilterServicePartnerId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>userFilterServicePartnerId, 'user_filter.service_partner_id');
        }
        if (userFilterOnlyGaClaims !== undefined && userFilterOnlyGaClaims !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>userFilterOnlyGaClaims, 'user_filter.only_ga_claims');
        }
        if (userFilterClaimTypes !== undefined && userFilterClaimTypes !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>userFilterClaimTypes, 'user_filter.claim_types');
        }
        if (userFilterInternalNote !== undefined && userFilterInternalNote !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>userFilterInternalNote, 'user_filter.internal_note');
        }
        if (startDate !== undefined && startDate !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>startDate, 'start_date');
        }
        if (endDate !== undefined && endDate !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>endDate, 'end_date');
        }
        if (apiVersion !== undefined && apiVersion !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>apiVersion, 'api_version');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (identity_server) required
        localVarCredential = this.configuration.lookupCredential('identity_server');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        return this.httpClient.get(`${this.configuration.basePath}/claim_desk/export`,
            {
                params: localVarQueryParameters,
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param apiVersion The requested API version
     * @param statusState 
     * @param statusForDecisionServicePartner 
     * @param productLineEquipmentType 
     * @param periodDayDiffFrom 
     * @param periodDayDiffTo 
     * @param periodFilterType 
     * @param acceptanceRatioFilterType 
     * @param acceptanceRatioTableType 
     * @param acceptanceRatioPartnerType 
     * @param userFilterClaimNumber 
     * @param userFilterEquipmentNumber 
     * @param userFilterDeliveryNote 
     * @param userFilterClaimStates 
     * @param userFilterEquipmentType 
     * @param userFilterDateType 
     * @param userFilterServicePartnerId 
     * @param userFilterOnlyGaClaims 
     * @param userFilterClaimTypes 
     * @param userFilterInternalNote 
     * @param startDate 
     * @param endDate 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getClaimDeskSummary(apiVersion: '1.1', statusState?: 'Draft' | 'Sent' | 'Returned' | 'PartsRequested' | 'ReceivedFromSP' | 'PartsRequestedFromSP' | 'AcceptedFully' | 'AcceptedPartially' | 'Declined' | 'DecidedToSP' | 'PartsSentFromSP' | 'InformationRequestedFromSP' | 'InformationGivenToGA', statusForDecisionServicePartner?: boolean, productLineEquipmentType?: string, periodDayDiffFrom?: number, periodDayDiffTo?: number, periodFilterType?: 'ReturnedSentGroups' | 'RepairedSentGroups', acceptanceRatioFilterType?: 'Accepted' | 'NotAccepted' | 'DeclinedUnknownReason' | 'DeclinedMissingProductionData' | 'DeclinedIncompleteBasicData' | 'DeclinedMissingDataFile' | 'DeclinedSubmissionAfterDeadline' | 'DeclinedMaterialIssue' | 'DeclinedAttachmentMissing' | 'DeclinedTextAndCodingNotIdentical' | 'DeclinedOther', acceptanceRatioTableType?: 'Total' | 'HandlingLumpSum', acceptanceRatioPartnerType?: 'ServicePartner' | 'GeneralAgent', userFilterClaimNumber?: string, userFilterEquipmentNumber?: string, userFilterDeliveryNote?: string, userFilterClaimStates?: string, userFilterEquipmentType?: string, userFilterDateType?: 'NoDate' | 'Repair' | 'Create', userFilterServicePartnerId?: number, userFilterOnlyGaClaims?: boolean, userFilterClaimTypes?: string, userFilterInternalNote?: string, startDate?: string, endDate?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json',}): Observable<GetClaimdeskSummary>;
    public getClaimDeskSummary(apiVersion: '1.1', statusState?: 'Draft' | 'Sent' | 'Returned' | 'PartsRequested' | 'ReceivedFromSP' | 'PartsRequestedFromSP' | 'AcceptedFully' | 'AcceptedPartially' | 'Declined' | 'DecidedToSP' | 'PartsSentFromSP' | 'InformationRequestedFromSP' | 'InformationGivenToGA', statusForDecisionServicePartner?: boolean, productLineEquipmentType?: string, periodDayDiffFrom?: number, periodDayDiffTo?: number, periodFilterType?: 'ReturnedSentGroups' | 'RepairedSentGroups', acceptanceRatioFilterType?: 'Accepted' | 'NotAccepted' | 'DeclinedUnknownReason' | 'DeclinedMissingProductionData' | 'DeclinedIncompleteBasicData' | 'DeclinedMissingDataFile' | 'DeclinedSubmissionAfterDeadline' | 'DeclinedMaterialIssue' | 'DeclinedAttachmentMissing' | 'DeclinedTextAndCodingNotIdentical' | 'DeclinedOther', acceptanceRatioTableType?: 'Total' | 'HandlingLumpSum', acceptanceRatioPartnerType?: 'ServicePartner' | 'GeneralAgent', userFilterClaimNumber?: string, userFilterEquipmentNumber?: string, userFilterDeliveryNote?: string, userFilterClaimStates?: string, userFilterEquipmentType?: string, userFilterDateType?: 'NoDate' | 'Repair' | 'Create', userFilterServicePartnerId?: number, userFilterOnlyGaClaims?: boolean, userFilterClaimTypes?: string, userFilterInternalNote?: string, startDate?: string, endDate?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json',}): Observable<HttpResponse<GetClaimdeskSummary>>;
    public getClaimDeskSummary(apiVersion: '1.1', statusState?: 'Draft' | 'Sent' | 'Returned' | 'PartsRequested' | 'ReceivedFromSP' | 'PartsRequestedFromSP' | 'AcceptedFully' | 'AcceptedPartially' | 'Declined' | 'DecidedToSP' | 'PartsSentFromSP' | 'InformationRequestedFromSP' | 'InformationGivenToGA', statusForDecisionServicePartner?: boolean, productLineEquipmentType?: string, periodDayDiffFrom?: number, periodDayDiffTo?: number, periodFilterType?: 'ReturnedSentGroups' | 'RepairedSentGroups', acceptanceRatioFilterType?: 'Accepted' | 'NotAccepted' | 'DeclinedUnknownReason' | 'DeclinedMissingProductionData' | 'DeclinedIncompleteBasicData' | 'DeclinedMissingDataFile' | 'DeclinedSubmissionAfterDeadline' | 'DeclinedMaterialIssue' | 'DeclinedAttachmentMissing' | 'DeclinedTextAndCodingNotIdentical' | 'DeclinedOther', acceptanceRatioTableType?: 'Total' | 'HandlingLumpSum', acceptanceRatioPartnerType?: 'ServicePartner' | 'GeneralAgent', userFilterClaimNumber?: string, userFilterEquipmentNumber?: string, userFilterDeliveryNote?: string, userFilterClaimStates?: string, userFilterEquipmentType?: string, userFilterDateType?: 'NoDate' | 'Repair' | 'Create', userFilterServicePartnerId?: number, userFilterOnlyGaClaims?: boolean, userFilterClaimTypes?: string, userFilterInternalNote?: string, startDate?: string, endDate?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json',}): Observable<HttpEvent<GetClaimdeskSummary>>;
    public getClaimDeskSummary(apiVersion: '1.1', statusState?: 'Draft' | 'Sent' | 'Returned' | 'PartsRequested' | 'ReceivedFromSP' | 'PartsRequestedFromSP' | 'AcceptedFully' | 'AcceptedPartially' | 'Declined' | 'DecidedToSP' | 'PartsSentFromSP' | 'InformationRequestedFromSP' | 'InformationGivenToGA', statusForDecisionServicePartner?: boolean, productLineEquipmentType?: string, periodDayDiffFrom?: number, periodDayDiffTo?: number, periodFilterType?: 'ReturnedSentGroups' | 'RepairedSentGroups', acceptanceRatioFilterType?: 'Accepted' | 'NotAccepted' | 'DeclinedUnknownReason' | 'DeclinedMissingProductionData' | 'DeclinedIncompleteBasicData' | 'DeclinedMissingDataFile' | 'DeclinedSubmissionAfterDeadline' | 'DeclinedMaterialIssue' | 'DeclinedAttachmentMissing' | 'DeclinedTextAndCodingNotIdentical' | 'DeclinedOther', acceptanceRatioTableType?: 'Total' | 'HandlingLumpSum', acceptanceRatioPartnerType?: 'ServicePartner' | 'GeneralAgent', userFilterClaimNumber?: string, userFilterEquipmentNumber?: string, userFilterDeliveryNote?: string, userFilterClaimStates?: string, userFilterEquipmentType?: string, userFilterDateType?: 'NoDate' | 'Repair' | 'Create', userFilterServicePartnerId?: number, userFilterOnlyGaClaims?: boolean, userFilterClaimTypes?: string, userFilterInternalNote?: string, startDate?: string, endDate?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json',}): Observable<any> {
        if (apiVersion === null || apiVersion === undefined) {
            throw new Error('Required parameter apiVersion was null or undefined when calling getClaimDeskSummary.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (statusState !== undefined && statusState !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>statusState, 'status.State');
        }
        if (statusForDecisionServicePartner !== undefined && statusForDecisionServicePartner !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>statusForDecisionServicePartner, 'status.ForDecisionServicePartner');
        }
        if (productLineEquipmentType !== undefined && productLineEquipmentType !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>productLineEquipmentType, 'product_line.EquipmentType');
        }
        if (periodDayDiffFrom !== undefined && periodDayDiffFrom !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>periodDayDiffFrom, 'period.DayDiffFrom');
        }
        if (periodDayDiffTo !== undefined && periodDayDiffTo !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>periodDayDiffTo, 'period.DayDiffTo');
        }
        if (periodFilterType !== undefined && periodFilterType !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>periodFilterType, 'period.FilterType');
        }
        if (acceptanceRatioFilterType !== undefined && acceptanceRatioFilterType !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>acceptanceRatioFilterType, 'acceptance_ratio.FilterType');
        }
        if (acceptanceRatioTableType !== undefined && acceptanceRatioTableType !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>acceptanceRatioTableType, 'acceptance_ratio.TableType');
        }
        if (acceptanceRatioPartnerType !== undefined && acceptanceRatioPartnerType !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>acceptanceRatioPartnerType, 'acceptance_ratio.PartnerType');
        }
        if (userFilterClaimNumber !== undefined && userFilterClaimNumber !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>userFilterClaimNumber, 'user_filter.claim_number');
        }
        if (userFilterEquipmentNumber !== undefined && userFilterEquipmentNumber !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>userFilterEquipmentNumber, 'user_filter.equipment_number');
        }
        if (userFilterDeliveryNote !== undefined && userFilterDeliveryNote !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>userFilterDeliveryNote, 'user_filter.delivery_note');
        }
        if (userFilterClaimStates !== undefined && userFilterClaimStates !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>userFilterClaimStates, 'user_filter.claim_states');
        }
        if (userFilterEquipmentType !== undefined && userFilterEquipmentType !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>userFilterEquipmentType, 'user_filter.equipment_type');
        }
        if (userFilterDateType !== undefined && userFilterDateType !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>userFilterDateType, 'user_filter.date_type');
        }
        if (userFilterServicePartnerId !== undefined && userFilterServicePartnerId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>userFilterServicePartnerId, 'user_filter.service_partner_id');
        }
        if (userFilterOnlyGaClaims !== undefined && userFilterOnlyGaClaims !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>userFilterOnlyGaClaims, 'user_filter.only_ga_claims');
        }
        if (userFilterClaimTypes !== undefined && userFilterClaimTypes !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>userFilterClaimTypes, 'user_filter.claim_types');
        }
        if (userFilterInternalNote !== undefined && userFilterInternalNote !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>userFilterInternalNote, 'user_filter.internal_note');
        }
        if (startDate !== undefined && startDate !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>startDate, 'start_date');
        }
        if (endDate !== undefined && endDate !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>endDate, 'end_date');
        }
        if (apiVersion !== undefined && apiVersion !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>apiVersion, 'api_version');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (identity_server) required
        localVarCredential = this.configuration.lookupCredential('identity_server');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        return this.httpClient.get<GetClaimdeskSummary>(`${this.configuration.basePath}/claim_desk/desk_summary`,
            {
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param apiVersion The requested API version
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getClaimdeskOptions(apiVersion: '1.1', observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json',}): Observable<GetClaimdeskOptionsResponse>;
    public getClaimdeskOptions(apiVersion: '1.1', observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json',}): Observable<HttpResponse<GetClaimdeskOptionsResponse>>;
    public getClaimdeskOptions(apiVersion: '1.1', observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json',}): Observable<HttpEvent<GetClaimdeskOptionsResponse>>;
    public getClaimdeskOptions(apiVersion: '1.1', observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json',}): Observable<any> {
        if (apiVersion === null || apiVersion === undefined) {
            throw new Error('Required parameter apiVersion was null or undefined when calling getClaimdeskOptions.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (apiVersion !== undefined && apiVersion !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>apiVersion, 'api_version');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (identity_server) required
        localVarCredential = this.configuration.lookupCredential('identity_server');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        return this.httpClient.get<GetClaimdeskOptionsResponse>(`${this.configuration.basePath}/claim_desk/options`,
            {
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param apiVersion The requested API version
     * @param pageIndex 
     * @param pageSize 
     * @param sortConcatenation 
     * @param statusState 
     * @param statusForDecisionServicePartner 
     * @param productLineEquipmentType 
     * @param periodDayDiffFrom 
     * @param periodDayDiffTo 
     * @param periodFilterType 
     * @param acceptanceRatioFilterType 
     * @param acceptanceRatioTableType 
     * @param acceptanceRatioPartnerType 
     * @param userFilterClaimNumber 
     * @param userFilterEquipmentNumber 
     * @param userFilterDeliveryNote 
     * @param userFilterClaimStates 
     * @param userFilterEquipmentType 
     * @param userFilterDateType 
     * @param userFilterServicePartnerId 
     * @param userFilterOnlyGaClaims 
     * @param userFilterClaimTypes 
     * @param userFilterInternalNote 
     * @param startDate 
     * @param endDate 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listClaimDeskClaims(apiVersion: '1.1', pageIndex?: number, pageSize?: number, sortConcatenation?: string, statusState?: 'Draft' | 'Sent' | 'Returned' | 'PartsRequested' | 'ReceivedFromSP' | 'PartsRequestedFromSP' | 'AcceptedFully' | 'AcceptedPartially' | 'Declined' | 'DecidedToSP' | 'PartsSentFromSP' | 'InformationRequestedFromSP' | 'InformationGivenToGA', statusForDecisionServicePartner?: boolean, productLineEquipmentType?: string, periodDayDiffFrom?: number, periodDayDiffTo?: number, periodFilterType?: 'ReturnedSentGroups' | 'RepairedSentGroups', acceptanceRatioFilterType?: 'Accepted' | 'NotAccepted' | 'DeclinedUnknownReason' | 'DeclinedMissingProductionData' | 'DeclinedIncompleteBasicData' | 'DeclinedMissingDataFile' | 'DeclinedSubmissionAfterDeadline' | 'DeclinedMaterialIssue' | 'DeclinedAttachmentMissing' | 'DeclinedTextAndCodingNotIdentical' | 'DeclinedOther', acceptanceRatioTableType?: 'Total' | 'HandlingLumpSum', acceptanceRatioPartnerType?: 'ServicePartner' | 'GeneralAgent', userFilterClaimNumber?: string, userFilterEquipmentNumber?: string, userFilterDeliveryNote?: string, userFilterClaimStates?: string, userFilterEquipmentType?: string, userFilterDateType?: 'NoDate' | 'Repair' | 'Create', userFilterServicePartnerId?: number, userFilterOnlyGaClaims?: boolean, userFilterClaimTypes?: string, userFilterInternalNote?: string, startDate?: string, endDate?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json',}): Observable<ListDeskClaimsResponse>;
    public listClaimDeskClaims(apiVersion: '1.1', pageIndex?: number, pageSize?: number, sortConcatenation?: string, statusState?: 'Draft' | 'Sent' | 'Returned' | 'PartsRequested' | 'ReceivedFromSP' | 'PartsRequestedFromSP' | 'AcceptedFully' | 'AcceptedPartially' | 'Declined' | 'DecidedToSP' | 'PartsSentFromSP' | 'InformationRequestedFromSP' | 'InformationGivenToGA', statusForDecisionServicePartner?: boolean, productLineEquipmentType?: string, periodDayDiffFrom?: number, periodDayDiffTo?: number, periodFilterType?: 'ReturnedSentGroups' | 'RepairedSentGroups', acceptanceRatioFilterType?: 'Accepted' | 'NotAccepted' | 'DeclinedUnknownReason' | 'DeclinedMissingProductionData' | 'DeclinedIncompleteBasicData' | 'DeclinedMissingDataFile' | 'DeclinedSubmissionAfterDeadline' | 'DeclinedMaterialIssue' | 'DeclinedAttachmentMissing' | 'DeclinedTextAndCodingNotIdentical' | 'DeclinedOther', acceptanceRatioTableType?: 'Total' | 'HandlingLumpSum', acceptanceRatioPartnerType?: 'ServicePartner' | 'GeneralAgent', userFilterClaimNumber?: string, userFilterEquipmentNumber?: string, userFilterDeliveryNote?: string, userFilterClaimStates?: string, userFilterEquipmentType?: string, userFilterDateType?: 'NoDate' | 'Repair' | 'Create', userFilterServicePartnerId?: number, userFilterOnlyGaClaims?: boolean, userFilterClaimTypes?: string, userFilterInternalNote?: string, startDate?: string, endDate?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json',}): Observable<HttpResponse<ListDeskClaimsResponse>>;
    public listClaimDeskClaims(apiVersion: '1.1', pageIndex?: number, pageSize?: number, sortConcatenation?: string, statusState?: 'Draft' | 'Sent' | 'Returned' | 'PartsRequested' | 'ReceivedFromSP' | 'PartsRequestedFromSP' | 'AcceptedFully' | 'AcceptedPartially' | 'Declined' | 'DecidedToSP' | 'PartsSentFromSP' | 'InformationRequestedFromSP' | 'InformationGivenToGA', statusForDecisionServicePartner?: boolean, productLineEquipmentType?: string, periodDayDiffFrom?: number, periodDayDiffTo?: number, periodFilterType?: 'ReturnedSentGroups' | 'RepairedSentGroups', acceptanceRatioFilterType?: 'Accepted' | 'NotAccepted' | 'DeclinedUnknownReason' | 'DeclinedMissingProductionData' | 'DeclinedIncompleteBasicData' | 'DeclinedMissingDataFile' | 'DeclinedSubmissionAfterDeadline' | 'DeclinedMaterialIssue' | 'DeclinedAttachmentMissing' | 'DeclinedTextAndCodingNotIdentical' | 'DeclinedOther', acceptanceRatioTableType?: 'Total' | 'HandlingLumpSum', acceptanceRatioPartnerType?: 'ServicePartner' | 'GeneralAgent', userFilterClaimNumber?: string, userFilterEquipmentNumber?: string, userFilterDeliveryNote?: string, userFilterClaimStates?: string, userFilterEquipmentType?: string, userFilterDateType?: 'NoDate' | 'Repair' | 'Create', userFilterServicePartnerId?: number, userFilterOnlyGaClaims?: boolean, userFilterClaimTypes?: string, userFilterInternalNote?: string, startDate?: string, endDate?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json',}): Observable<HttpEvent<ListDeskClaimsResponse>>;
    public listClaimDeskClaims(apiVersion: '1.1', pageIndex?: number, pageSize?: number, sortConcatenation?: string, statusState?: 'Draft' | 'Sent' | 'Returned' | 'PartsRequested' | 'ReceivedFromSP' | 'PartsRequestedFromSP' | 'AcceptedFully' | 'AcceptedPartially' | 'Declined' | 'DecidedToSP' | 'PartsSentFromSP' | 'InformationRequestedFromSP' | 'InformationGivenToGA', statusForDecisionServicePartner?: boolean, productLineEquipmentType?: string, periodDayDiffFrom?: number, periodDayDiffTo?: number, periodFilterType?: 'ReturnedSentGroups' | 'RepairedSentGroups', acceptanceRatioFilterType?: 'Accepted' | 'NotAccepted' | 'DeclinedUnknownReason' | 'DeclinedMissingProductionData' | 'DeclinedIncompleteBasicData' | 'DeclinedMissingDataFile' | 'DeclinedSubmissionAfterDeadline' | 'DeclinedMaterialIssue' | 'DeclinedAttachmentMissing' | 'DeclinedTextAndCodingNotIdentical' | 'DeclinedOther', acceptanceRatioTableType?: 'Total' | 'HandlingLumpSum', acceptanceRatioPartnerType?: 'ServicePartner' | 'GeneralAgent', userFilterClaimNumber?: string, userFilterEquipmentNumber?: string, userFilterDeliveryNote?: string, userFilterClaimStates?: string, userFilterEquipmentType?: string, userFilterDateType?: 'NoDate' | 'Repair' | 'Create', userFilterServicePartnerId?: number, userFilterOnlyGaClaims?: boolean, userFilterClaimTypes?: string, userFilterInternalNote?: string, startDate?: string, endDate?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json',}): Observable<any> {
        if (apiVersion === null || apiVersion === undefined) {
            throw new Error('Required parameter apiVersion was null or undefined when calling listClaimDeskClaims.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (pageIndex !== undefined && pageIndex !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>pageIndex, 'page_index');
        }
        if (pageSize !== undefined && pageSize !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>pageSize, 'page_size');
        }
        if (sortConcatenation !== undefined && sortConcatenation !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sortConcatenation, 'sort_concatenation');
        }
        if (statusState !== undefined && statusState !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>statusState, 'status.State');
        }
        if (statusForDecisionServicePartner !== undefined && statusForDecisionServicePartner !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>statusForDecisionServicePartner, 'status.ForDecisionServicePartner');
        }
        if (productLineEquipmentType !== undefined && productLineEquipmentType !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>productLineEquipmentType, 'product_line.EquipmentType');
        }
        if (periodDayDiffFrom !== undefined && periodDayDiffFrom !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>periodDayDiffFrom, 'period.DayDiffFrom');
        }
        if (periodDayDiffTo !== undefined && periodDayDiffTo !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>periodDayDiffTo, 'period.DayDiffTo');
        }
        if (periodFilterType !== undefined && periodFilterType !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>periodFilterType, 'period.FilterType');
        }
        if (acceptanceRatioFilterType !== undefined && acceptanceRatioFilterType !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>acceptanceRatioFilterType, 'acceptance_ratio.FilterType');
        }
        if (acceptanceRatioTableType !== undefined && acceptanceRatioTableType !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>acceptanceRatioTableType, 'acceptance_ratio.TableType');
        }
        if (acceptanceRatioPartnerType !== undefined && acceptanceRatioPartnerType !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>acceptanceRatioPartnerType, 'acceptance_ratio.PartnerType');
        }
        if (userFilterClaimNumber !== undefined && userFilterClaimNumber !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>userFilterClaimNumber, 'user_filter.claim_number');
        }
        if (userFilterEquipmentNumber !== undefined && userFilterEquipmentNumber !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>userFilterEquipmentNumber, 'user_filter.equipment_number');
        }
        if (userFilterDeliveryNote !== undefined && userFilterDeliveryNote !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>userFilterDeliveryNote, 'user_filter.delivery_note');
        }
        if (userFilterClaimStates !== undefined && userFilterClaimStates !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>userFilterClaimStates, 'user_filter.claim_states');
        }
        if (userFilterEquipmentType !== undefined && userFilterEquipmentType !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>userFilterEquipmentType, 'user_filter.equipment_type');
        }
        if (userFilterDateType !== undefined && userFilterDateType !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>userFilterDateType, 'user_filter.date_type');
        }
        if (userFilterServicePartnerId !== undefined && userFilterServicePartnerId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>userFilterServicePartnerId, 'user_filter.service_partner_id');
        }
        if (userFilterOnlyGaClaims !== undefined && userFilterOnlyGaClaims !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>userFilterOnlyGaClaims, 'user_filter.only_ga_claims');
        }
        if (userFilterClaimTypes !== undefined && userFilterClaimTypes !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>userFilterClaimTypes, 'user_filter.claim_types');
        }
        if (userFilterInternalNote !== undefined && userFilterInternalNote !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>userFilterInternalNote, 'user_filter.internal_note');
        }
        if (startDate !== undefined && startDate !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>startDate, 'start_date');
        }
        if (endDate !== undefined && endDate !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>endDate, 'end_date');
        }
        if (apiVersion !== undefined && apiVersion !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>apiVersion, 'api_version');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (identity_server) required
        localVarCredential = this.configuration.lookupCredential('identity_server');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        return this.httpClient.get<ListDeskClaimsResponse>(`${this.configuration.basePath}/claim_desk/desk_claims`,
            {
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
