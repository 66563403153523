<div
  fxLayout="row"
  fxLayout.lt-md="column"
  fxLayoutAlign="space-between baseline"
  fxLayoutAlign.lt-md="start"
  [formGroup]="filterForm"
  gaCategoryGroup="DashboardReturnedToSubmitDaysChart"
>
  <div
    fxLayout="row"
    fxLayoutAlign="start center"
    [fxLayoutGap]="0.5 | dsSpacing"
  >
    <strong>
      {{ 'claim-management.dashboard.days_between' | translate }}:</strong
    >
    <mat-radio-group
      fxLayout="row"
      [fxLayoutGap]="0.5 | dsSpacing"
      formControlName="dataType"
    >
      <mat-radio-button
        [value]="dataType.ReturnSubmit"
        gaClickListener="SelectReturnToSubmit"
      >
        {{ 'claim-management.dashboard.return_submit' | translate }}
      </mat-radio-button>
      <mat-radio-button
        [value]="dataType.RepairSubmit"
        gaClickListener="SelectRepairToSubmit"
      >
        {{ 'claim-management.dashboard.repair_submit' | translate }}
      </mat-radio-button>
    </mat-radio-group>
  </div>
  <div fxLayout="row wrap" [fxLayoutGap]="(1 | dsSpacing) + ' grid'">
    <mat-form-field [fxFlex]="10 | dsSpacing" [subscriptSizing]="'dynamic'">
      <mat-label>{{
        'claim-management.dashboard.days_threshold' | translate
      }}</mat-label>
      <mat-select
        [formControlName]="'dayDiffThreshold'"
        gaClickListener="SelectDaysOffset"
      >
        <mat-option [value]="0">-</mat-option>
        <mat-option *ngFor="let days of [1, 5, 10, 30]" [value]="days + 1"
          >> {{ days }}</mat-option
        >
      </mat-select>
    </mat-form-field>
    <mat-form-field [formGroup]="dateRange" [subscriptSizing]="'dynamic'">
      <mat-label>{{
        'claim-management.dashboard.date_range' | translate
      }}</mat-label>
      <mat-date-range-input
        [rangePicker]="picker"
        [max]="today"
        [min]="'01 Jan 2020'"
        gaClickListener="SelectDateRange"
      >
        <input
          matStartDate
          formControlName="startDate"
          readonly
          (click)="picker.open()"
        />
        <input
          matEndDate
          formControlName="endDate"
          readonly
          (click)="picker.open()"
        />
      </mat-date-range-input>
      <div matIconSuffix class="nowrap-text">
        <mat-datepicker-toggle [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker-toggle (click)="dateRange.reset(); getChartData()">
          <mat-icon matDatepickerToggleIcon>clear</mat-icon>
        </mat-datepicker-toggle>
      </div>

      <mat-date-range-picker
        (closed)="getChartData()"
        [calendarHeaderComponent]="DsPresetCalenderHeaderComponent"
        #picker
      ></mat-date-range-picker>
    </mat-form-field>
  </div>
</div>

<div
  *ngIf="results$ | async as results; else chartLoading"
  [ngStyle]="{ height: 25 | dsSpacing, width: '100%' }"
>
  <ngx-charts-bubble-chart
    *ngIf="
      (results && !!results[0]?.series?.length) || !!results[1]?.series?.length;
      else noData
    "
    [legend]="
      results && !!results[0]?.series?.length && !!results[1]?.series?.length
    "
    [results]="results"
    [minRadius]="3"
    [maxRadius]="10"
    [yAxisLabel]="'days'"
    [yScaleMax]="yAxisMax"
    [showYAxisLabel]="true"
    (select)="onBubbleClick($event)"
  >
    <ng-template #tooltipTemplate let-claim="model">
      <br />
      {{ claim.name }} <br />
      <small>({{ claim.x | date: 'shortDate' }})</small>
      <br />
      <strong> {{ claim.r | currency: claim.currency : 'symbol' }}</strong>
      <h4>{{ claim.y }} day(s)</h4>
      <br />
    </ng-template>
  </ngx-charts-bubble-chart>
</div>

<ng-template #chartLoading>
  <div [ngStyle]="{ height: 25 | dsSpacing, position: 'relative' }">
    <mat-progress-spinner
      color="accent"
      [diameter]="100"
      [mode]="'indeterminate'"
      class="loading-spinner"
    ></mat-progress-spinner>
  </div>
</ng-template>

<ng-template #noData>
  <ds-placeholder [type]="'no_data'"></ds-placeholder>
</ng-template>
