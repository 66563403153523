import {
  AbstractControl,
  AsyncValidatorFn,
  ValidationErrors,
} from '@angular/forms';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { api_version } from '../constants';
import {
  RecallActionService,
  ValidateRecallActionIdUniqueResponseValidationUniqueResult,
} from '@claim-management-lib/data-access';

export function RecallActionIdValidator(
  recallActionService: RecallActionService,
): AsyncValidatorFn {
  return (ctrl: AbstractControl): Observable<ValidationErrors | null> => {
    if (!ctrl.value) {
      return of(null);
    }
    return recallActionService
      .validateRecallActionIdUnique(ctrl.value.trim(), api_version)
      .pipe(
        map((check) =>
          check.validation_unique_result ===
          ValidateRecallActionIdUniqueResponseValidationUniqueResult.Valid
            ? null
            : { recallActionIdIsInvalid: true },
        ),
        catchError(() => of({ recallActionIdIsInvalid: true })),
      );
  };
}
