/**
 * v1.1 - Claim Management API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1_claim_management
 * Contact: F.John@palfinger.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface GetDecisionReasonTypesResponse { 
    
    available_decision_reasons?: Array<GetDecisionReasonTypesResponseAvailableDecisionReasons>;
}
export enum GetDecisionReasonTypesResponseAvailableDecisionReasons {
    Accepted = 'Accepted',
    DeclinedMissingProductionData = 'DeclinedMissingProductionData',
    DeclinedIncompleteBasicData = 'DeclinedIncompleteBasicData',
    DeclinedMissingDataFile = 'DeclinedMissingDataFile',
    DeclinedSubmissionAfterDeadline = 'DeclinedSubmissionAfterDeadline',
    DeclinedMaterialIssue = 'DeclinedMaterialIssue',
    DeclinedAttachmentMissing = 'DeclinedAttachmentMissing',
    DeclinedTextAndCodingNotIdentical = 'DeclinedTextAndCodingNotIdentical',
    DeclinedOther = 'DeclinedOther'
};



