/**
 * v1.1 - Claim Management API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1_claim_management
 * Contact: F.John@palfinger.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface CreditNote { 
    
    credit_note_number: string;
    
    is_custom_created: boolean;
    
    credit_note_type: CreditNoteCreditNoteType;
}
export enum CreditNoteCreditNoteType {
    Area = 'Area',
    GeneralAgent = 'GeneralAgent'
};



