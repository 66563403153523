import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'localeDatePipe',
})
export class LocaleDatePipe implements PipeTransform {
  private localeId: string;
  constructor(@Inject(LOCALE_ID) localeId: string) {
    this.localeId = localeId;
  }
  //Used for formating dates created in FE, for formating BE dates update needs to be done
  transform(date?: Date): any {
    if (!date) {
      return date;
    }
    const options: Intl.DateTimeFormatOptions = {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
    };

    return date.toLocaleDateString(this.localeId, options);
  }
}
