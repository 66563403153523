<div *ngFor="let info of data; let first = first">
  <h5 [ngClass]="{ 'no-margin-top': first }">
    {{ info.label | translate
    }}<ng-container *ngIf="info.value !== undefined">:</ng-container>
  </h5>
  <div *ngIf="!info.displayContractTerms; else contractTypeLink">
    <span [innerHtml]="info.value"></span>
    <small *ngIf="info.repairDateInWarrantyPeriod !== undefined">
      &nbsp;
      <mat-icon
        inline
        [ngClass]="{
          'info-icon': !info.repairDateInWarrantyPeriod
        }"
        >{{ info.repairDateInWarrantyPeriod ? 'check_circle' : 'info' }}
      </mat-icon>
      <span>
        {{
          (info.repairDateInWarrantyPeriod
            ? 'claim-management.summary.warranty_in'
            : 'claim-management.summary.warranty_out'
          ) | translate
        }}</span
      >
    </small>
  </div>
  <ng-template #contractTypeLink>
    <a (click)="openContractTerms()" class="pointer"> {{ info.value }}</a>
  </ng-template>
</div>
