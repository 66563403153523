<div
  fxLayout="row"
  fxLayout.lt-md="column"
  [fxLayoutGap]="1 | dsSpacing"
  fxLayoutGap.lt-md="0"
  [formGroup]="form"
>
  <cm-spare-part
    fxFlex
    fxFlex.lt-md="100%"
    [materialNumberRequired]="materialNumberRequired"
    [servicePartnerId]="servicePartnerId"
    [getMaterialInfoDto]="sparePartValidationDto"
    formControlName="fault_causing_part"
  ></cm-spare-part>
  <mat-form-field fxFlex fxFlex.lt-md="100%">
    <mat-label>{{
      'claim-management.repairDescription.serialNumber' | translate
    }}</mat-label>
    <input
      matInput
      (keypress)="preventDefault($event)"
      [maxLength]="35"
      autocomplete="off"
      formControlName="fault_serial_number"
      data-cy="fault_serial_number"
    />
    <mat-error
      >{{ 'claim-management.repairDescription.error_serialNumber' | translate }}
    </mat-error>
  </mat-form-field>
</div>
