<h1 mat-dialog-title>{{ title }}</h1>
<div mat-dialog-content>
  <p [innerHTML]="content"></p>
  <ul *ngIf="list">
    <li *ngFor="let item of list">{{ item }}</li>
  </ul>
</div>
<div mat-dialog-actions>
  <button *ngIf="cancelbutton" mat-raised-button matDialogClose cdkFocusInitial>
    {{ 'general.cancel' | translate }}
  </button>
  <button
    mat-button
    *ngIf="!hideCancelbutton"
    [mat-dialog-close]="false"
    cdkFocusInitial
    data-cy="prompt_cancel"
  >
    {{ cancelText || ('general.cancel' | translate) }}
  </button>
  <button
    mat-raised-button
    color="accent"
    [mat-dialog-close]="true"
    data-cy="prompt_ok"
  >
    {{ confirmText || ('general.confirm' | translate) }}
  </button>
</div>
