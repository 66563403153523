<h1 mat-dialog-title>
  {{ 'claim-management.palcode.paltronic_error_codes' | translate }}
</h1>
<mat-dialog-content>
  <mat-form-field class="full-width">
    <mat-label> {{ 'claim-management.palcode.code' | translate }}</mat-label>
    <input matInput type="text" [formControl]="code" />
  </mat-form-field>
  <ng-container *pdLet="dataSource$ | async as searchResults">
    <ds-table *ngIf="searchResults || searchPending">
      <ds-table-loading
        *ngIf="searchPending; else result"
        [numberOfHeaders]="4"
        [numberOfRows]="10"
      ></ds-table-loading>
      <ng-template #result>
        <table
          mat-table
          [dataSource]="searchResults"
          *ngIf="searchResults.data?.length; else noResult"
        >
          <ng-container matColumnDef="code">
            <th mat-header-cell *matHeaderCellDef>
              {{ 'claim-management.palcode.code' | translate }}
            </th>
            <td mat-cell *matCellDef="let result">
              {{ result.code }}
            </td>
          </ng-container>

          <ng-container matColumnDef="cause">
            <th mat-header-cell *matHeaderCellDef>
              {{ 'claim-management.palcode.reason' | translate }}
            </th>
            <td mat-cell *matCellDef="let result">
              {{ result.cause }}
            </td>
          </ng-container>

          <ng-container matColumnDef="description">
            <th mat-header-cell *matHeaderCellDef>
              {{ 'claim-management.palcode.description' | translate }}
            </th>
            <td mat-cell *matCellDef="let result">
              {{ result.description }}
            </td>
          </ng-container>

          <ng-container matColumnDef="actions" stickyEnd>
            <th mat-header-cell *matHeaderCellDef class="normal-white-space">
              {{ 'claim-management.palcode.add_to_description' | translate }}
            </th>
            <td mat-cell *matCellDef="let result" class="text-center">
              <button
                mat-icon-button
                [mat-dialog-close]="
                  result.code + ' ' + result.cause + ' ' + result.description
                "
              >
                <mat-icon>chevron_right</mat-icon>
              </button>
            </td>
          </ng-container>

          <tr
            mat-header-row
            *matHeaderRowDef="['code', 'cause', 'description', 'actions']"
          ></tr>
          <tr
            mat-row
            *matRowDef="
              let row;
              columns: ['code', 'cause', 'description', 'actions']
            "
          ></tr>
        </table>
      </ng-template>
      <ds-table-footer>
        <mat-paginator [pageSizeOptions]="[5, 10, 25, 50]"></mat-paginator>
      </ds-table-footer>
    </ds-table>
  </ng-container>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button type="button" mat-dialog-close>
    {{ 'general.close' | translate }}
  </button>
</mat-dialog-actions>

<ng-template #noResult>
  <ds-placeholder
    type="no_data"
    [customHeadline]="'claim-management.palcode.no_result' | translate"
  ></ds-placeholder>
</ng-template>
