<h3 mat-dialog-title>
  {{ 'claim-management.dashboard.notifications_settings' | translate }}
</h3>
<mat-dialog-content>
  <div *ngIf="!isLoading; else showLoading">
    <ds-text-indicator [type]="'info'">{{
      'claim-management.dashboard.notifications_settings_hint' | translate
    }}</ds-text-indicator>
    <br />
    <paldesk-email-addresses
      [labelKey]="'claim-management.dashboard.standard_claims'"
      [formControl]="emailListControl"
    ></paldesk-email-addresses>
    <br />
    <br />
    <br />
    <paldesk-email-addresses
      [labelKey]="'claim-management.dashboard.delivery_claims'"
      [formControl]="emailListDeliveryControl"
    ></paldesk-email-addresses>
    <br />
    <br />
    <br />
    <mat-form-field class="full-width">
      <mat-label>
        {{ 'general.language' | translate }}
      </mat-label>
      <mat-select [formControl]="languageControl">
        <mat-option
          *ngFor="let languageCode of languageCodes"
          [value]="languageCode"
        >
          {{
            'claim-management.dashboard.languages.' + languageCode | translate
          }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <div fxLayout="row" [fxLayoutGap]="1 | dsSpacing">
      <mat-slide-toggle [(ngModel)]="notificationsActive">{{
        (notificationsActive
          ? 'claim-management.dashboard.notifications_active'
          : 'claim-management.dashboard.notifications_inactive'
        ) | translate
      }}</mat-slide-toggle>
      <ds-text-indicator [type]="'info'">{{
        (notificationsActive
          ? 'claim-management.dashboard.notifications_inactive_hint'
          : 'claim-management.dashboard.notifications_active_hint'
        ) | translate
      }}</ds-text-indicator>
    </div>
  </div>

  <ng-template #showLoading
    ><mat-progress-bar mode="indeterminate"></mat-progress-bar
  ></ng-template>
</mat-dialog-content>
<mat-dialog-actions>
  <ds-text-indicator *ngIf="modifiedByGeneralAgent" [type]="'warning'">{{
    'claim-management.dashboard.notifications_modified_by_general_agent'
      | translate
  }}</ds-text-indicator>
  <button mat-button mat-dialog-close [disabled]="savePending">
    {{ 'general.cancel' | translate }}
  </button>
  <button
    (click)="saveSettings()"
    mat-raised-button
    color="accent"
    [disabled]="saveDisabled"
    [dsButtonPending]="savePending"
  >
    {{ 'general.save' | translate }}
  </button></mat-dialog-actions
>
