import { ValidatorFn } from '@angular/forms';
import { SparePartComponent } from './spare-part.component';

export enum SparePartErrorMessageKeys {
  sparePartValidating = 'sparePartValidating',
  required = 'required',
  invalidSparePart = 'invalidSparePart',
  unique = 'unique',
}

export interface SparePartFormValues {
  materialId: number;
  materialNumber?: string;
  price?: number;
  materialDescription?: string;
  isPriceEditable: boolean;
  spare_part_id?: number;
}

/**
 * Collection of reusable error messages
 */
export const errorMessages: { [key: string]: string } = {
  sparePartValidating: 'claim-management.spare_part.validating',
  required: 'claim-management.spare_part.required',
  invalidSparePart: 'claim-management.spare_part.invalid',
  unique: 'claim-management.spare_part.unique',
};

export class SparePartValidators {
  static validating(sparePartComponent: SparePartComponent): ValidatorFn {
    return () => {
      if (sparePartComponent.isValidating) {
        return { sparePartValidating: true };
      }
      return null;
    };
  }

  static required(sparePartComponent: SparePartComponent): ValidatorFn {
    return () => {
      if (
        !sparePartComponent.isValidating &&
        sparePartComponent.materialNumberRequired &&
        sparePartComponent.form &&
        sparePartComponent.form.value
      ) {
        const formValue = sparePartComponent.form.value as SparePartFormValues;
        if (!formValue.materialNumber) {
          return { required: true };
        }
      }
      return null;
    };
  }

  static validSparePart(sparePartComponent: SparePartComponent): ValidatorFn {
    return () => {
      if (
        !sparePartComponent.isValidating &&
        sparePartComponent.form &&
        sparePartComponent.form.value
      ) {
        const formValue = sparePartComponent.form.value as SparePartFormValues;
        if (formValue.materialNumber && formValue.materialId <= 0) {
          return { invalidSparePart: true };
        }
      }
      return null;
    };
  }

  static unique(sparePartComponent: SparePartComponent): ValidatorFn {
    return () => {
      const materialNumber = sparePartComponent.form?.value.materialNumber;
      return sparePartComponent.blackList.some(
        (x) =>
          materialNumber !== undefined &&
          materialNumber !== null &&
          x.toLowerCase() === materialNumber.toString().toLowerCase(),
      )
        ? {
            nonUniqueName: true,
          }
        : null;
    };
  }

  static forbiddenFaultPart(
    sparePartComponent: SparePartComponent,
  ): ValidatorFn {
    return () => {
      const materialNumber = sparePartComponent.form?.value.materialNumber;
      return !!materialNumber &&
        !!sparePartComponent.notAllowedFaultPart &&
        sparePartComponent.notAllowedFaultPart?.toLowerCase() ===
          materialNumber.toString().toLowerCase()
        ? {
            forbiddenFaultPart: true,
          }
        : null;
    };
  }
}
