import {
  ErrorCoding,
  ErrorCodings,
  ErrorCodingsDescriptions,
} from '@claim-management-lib/data-access';

export class ErrorCodingHelper {
  static findErrorCodingDescriptions(
    errorCodingTree: ErrorCoding[],
    selectedErrorCoding: ErrorCodings,
  ): ErrorCodingsDescriptions {
    const errorLocation = errorCodingTree.find(
      (c) => c.sap_code === selectedErrorCoding.error_location,
    );
    let errorLocationDetail: ErrorCoding | undefined;
    let errorType: ErrorCoding | undefined;
    let errorTypeDetail: ErrorCoding | undefined;
    if (errorLocation) {
      errorLocationDetail = errorLocation.children?.find(
        (c) => c.sap_code === selectedErrorCoding.error_location_detail,
      );
      if (errorLocationDetail) {
        errorType = errorLocationDetail.children?.find(
          (c) => c.sap_code === selectedErrorCoding.error_type,
        );
        if (errorType) {
          errorTypeDetail = errorType.children?.find(
            (c) => c.sap_code === selectedErrorCoding.error_type_detail,
          );
        }
      }
    }

    return {
      error_location_description: errorLocation?.description,
      error_location_detail_description: errorLocationDetail?.description,
      error_type_description: errorType?.description,
      error_type_detail_description: errorTypeDetail?.description,
    };
  }
}
