import { AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ClaimHistory, ClaimService } from '@claim-management-lib/data-access';
import {
  DsSnackbarService,
  DsSnackbarType,
} from '@design-system/feature/snackbar';
import { TranslateService } from '@ngx-translate/core';
import { EMPTY, Observable, finalize, map } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { api_version } from '../../constants';
import {
  decisionGaColumns,
  settingsKey,
  simpleColumns,
} from './table.settings';
import {
  TableSettings,
  TableSettingsService,
} from '@design-system/components/table-settings';

export interface ClaimHistoryLogData {
  claimId: number;
  displayAreaState: boolean;
}

@Component({
  selector: 'paldesk-claim-history-log-log',
  templateUrl: './claim-history-log.component.html',
  styleUrls: ['./claim-history-log.component.scss'],
})
export class ClaimHistoryLogComponent implements OnInit, AfterViewInit {
  historyLog$: Observable<ClaimHistory[]>;
  pending = true;
  settingsKey = settingsKey;
  columns: TableSettings[];

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: ClaimHistoryLogData,
    private dialogRef: MatDialogRef<ClaimHistoryLogComponent>,
    private claimService: ClaimService,
    private snackbar: DsSnackbarService,
    private translateService: TranslateService,
    private tableSettingsService: TableSettingsService,
  ) {
    this.columns = data.displayAreaState ? decisionGaColumns : simpleColumns;
  }

  ngOnInit(): void {
    this.historyLog$ = this.claimService
      .getClaimHistoryLog(this.data.claimId, api_version)
      .pipe(
        map((x) => x.claim_history_log || []),
        catchError(() => {
          this.snackbar.queue(
            this.translateService.instant(
              'claim-management.claim_history_error',
            ),
            {
              type: DsSnackbarType.Error,
            },
          );
          this.dialogRef.close();
          return EMPTY;
        }),
        finalize(() => (this.pending = false)),
      );
  }

  setUserSetting(userSettings: TableSettings[]): void {
    this.columns = userSettings;
  }

  ngAfterViewInit(): void {
    this.tableSettingsService.get(this.settingsKey, this.columns).subscribe({
      next: (storedUserColumns) => (this.columns = storedUserColumns),
    });
  }
}
