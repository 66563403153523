import { Injectable } from '@angular/core';
import {
  ClaimService,
  ClaimStateFilterRow,
} from '@claim-management-lib/data-access';
import { map, Observable, Subject } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { api_version } from '../constants';

@Injectable({ providedIn: 'root' })
export class RecentClaimService {
  private _stateFilter$ = new Subject<ClaimStateFilterRow[]>();
  public readonly stateFilterObservable: Observable<ClaimStateFilterRow[]> =
    this._stateFilter$.asObservable();

  constructor(private claimService: ClaimService) {}

  public getStateFilter(): void {
    this.claimService
      .getStateFilter(api_version)
      .pipe(map((r) => r.state_filter_rows))
      .subscribe({
        next: (stateFilter) => {
          this._stateFilter$.next(stateFilter);
        },
      });
  }

  public markClaimAsSeen(claimId: number): void {
    this.claimService
      .markClaimAsSeen(api_version, claimId)
      .pipe(finalize(() => this.getStateFilter()))
      .subscribe();
  }

  public markAllClaimsAsSeenObservable(): Observable<void> {
    return this.claimService
      .markAllClaimsAsSeen(api_version)
      .pipe(finalize(() => this.getStateFilter()));
  }
}
