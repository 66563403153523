<div [formGroup]="form" fxLayout="row" data-cy="sp_formGroup">
  <mat-form-field class="full-width" data-cy="sp_material_no_field">
    <mat-label>{{
      label ||
        ('claim-management.repairDescription.faultCausingPart' | translate)
    }}</mat-label>
    <input
      #spare_part_materialNumber
      matInput
      formControlName="materialNumber"
      type="text"
      class="form-control"
      (keypress)="preventDefault($event)"
      [errorStateMatcher]="validParentMatcher"
      data-cy="sp_material_no"
      required
      (focus)="onFocusChange($event)"
      (focusout)="onFocusChange($event)"
      [matAutocomplete]="autocomplete"
      (blur)="triggerMaterialNumberChange()"
    />
    <mat-autocomplete
      #autocomplete="matAutocomplete"
      (optionSelected)="triggerMaterialNumberChange()"
      class="spare-part-autocomplete"
    >
      <ng-container *ngIf="materialSuggestions$ | async as materials">
        <mat-option
          *ngFor="let material of materials"
          [value]="material.name"
          class="material-option"
        >
          <span class="material-name">
            {{ material.name }} <span *ngIf="material.description">-</span>
          </span>
          <small class="material-description">
            {{ material.description }}
          </small>
        </mat-option>
        <mat-option
          [disabled]="true"
          class="material-option material-name"
          *ngIf="materials.length === 10"
        >
          <strong>{{
            'claim-management.spareParts.moreResults' | translate
          }}</strong>
        </mat-option>
      </ng-container>
    </mat-autocomplete>
    <mat-progress-spinner
      matTextSuffix
      *ngIf="showLoading"
      [mode]="'indeterminate'"
      [diameter]="15"
      matTooltip="{{
        'claim-management.repairDescription.loading_faultCausingPart'
          | translate
      }}"
    ></mat-progress-spinner>

    <mat-icon *ngIf="showSuccess" matTextSuffix class="done">&#xE876;</mat-icon>

    <mat-error
      *ngIf="
        !form.hasError('nonUniqueName') && !form.hasError('sparePartValidating')
      "
      >{{
        'claim-management.spareParts.spare_part_invalid' | translate
      }}</mat-error
    >
    <mat-error *ngIf="form.hasError('nonUniqueName')">{{
      'claim-management.spareParts.duplicate_spare_part' | translate
    }}</mat-error>
    <mat-hint class="cut-text" matTooltip="{{ getDescriptionTooltip() }}">{{
      getDescriptionTooltip()
    }}</mat-hint>
  </mat-form-field>
</div>
