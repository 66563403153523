<div fxLayout="column" [fxLayoutGap]="1 | dsSpacing">
  <h1 fxLayout="row wrap" fxLayoutAlign="space-between baseline">
    <span>{{ 'claim-management.dashboard.dashboard' | translate }} </span>
  </h1>

  <div
    *pdLet="chartDataArea$ | async as chartDataArea"
    fxLayout="row"
    [fxLayout.lt-lg]="chartDataArea?.length ? 'column' : 'row'"
    fxLayout.lt-md="column"
    [fxLayoutGap]="1 | dsSpacing"
  >
    <div
      *ngIf="this.recentClaims$ | async as recentClaims; else loading"
      [fxFlex]="22 | dsSpacing"
      [fxFlex.lt-lg]="chartDataArea?.length ? 'auto' : (20 | dsSpacing)"
      fxFlex.lt-md="auto"
    >
      <mat-card
        class="full-height"
        *ngIf="filteredClaims?.length; else noResult"
      >
        <mat-card-header class="no-padding-bottom">
          <mat-card-title
            >{{ 'claim-management.dashboard.recent_claims' | translate }} ({{
              recentClaims?.length
            }})</mat-card-title
          >
        </mat-card-header>
        <mat-card-content>
          <mat-nav-list>
            <a
              mat-list-item
              *ngFor="let claim of filteredClaims"
              [routerLink]="
                getClaimLinkByNavigationType(claim.navigation_type) +
                claim.claim_id
              "
              gaCategoryGroup="DashboardRecentClaims"
              gaClickListener="RecentClaimsNavigateToClaim"
              ><strong matListItemTitle>{{ claim.claim_number }}</strong>
              <span matListItemLine
                ><strong
                  >{{
                    'claim-management.dashboard.status' | translate
                  }}:</strong
                >
                {{
                  'claim-management.dashboard.' + claim.state
                    | translate
                    | titlecase
                }}
              </span>
              <span matListItemLine *ngIf="claim.state_area"
                ><strong
                  >{{
                    'claim-management.dashboard.status_area' | translate
                  }}:</strong
                >
                {{
                  'claim-management.dashboard.' + claim.state_area
                    | translate
                    | titlecase
                }}
              </span>
              <span matListItemMeta fxLayoutAlign="end">{{
                claim.date_of_change | date: 'shortDate'
              }}</span>
            </a>
          </mat-nav-list>
        </mat-card-content>
        <mat-card-actions *ngIf="recentClaims?.length">
          <div
            fxLayout="row"
            fxLayoutAlign="space-between center"
            class="recent-claim-actions"
          >
            <button
              gaCategoryGroup="DashboardRecentClaims"
              gaClickListener="MarkAllClaimsAsSeen"
              mat-button
              (click)="markAllClaimsAsSeen()"
            >
              <mat-icon>done_all</mat-icon>
              {{ 'claim-management.dashboard.mark_all_as_seen' | translate }}
            </button>
            <mat-paginator
              *ngIf="recentClaims?.length && recentClaims.length > 5"
              cdkStorePageSize="claim_dashboard"
              [hidePageSize]="true"
              [length]="recentClaims?.length"
              [pageSize]="5"
              (page)="filterClaims($event, recentClaims)"
              gaCategoryGroup="DashboardRecentClaims"
              gaClickListener="ChangePage"
            >
            </mat-paginator>
          </div>
        </mat-card-actions>
      </mat-card>
    </div>

    <mat-card fxFlex fxLayout="column">
      <mat-card-header class="no-padding-bottom">
        <mat-card-title fxLayout="row" fxLayoutAlign="space-between start">
          {{ 'claim-management.dashboard.claims_by_status' | translate }}
        </mat-card-title>
      </mat-card-header>

      <mat-card-content
        class="claims-by-status-charts"
        fxFlex
        fxLayout="column"
        fxLayoutAlign="space-between center"
      >
        <div
          fxLayout="row"
          [fxLayoutGap]="1 | dsSpacing"
          fxLayout.lt-md="column"
        >
          <mat-form-field
            [formGroup]="dateRange"
            class="claims-by-status-date-picker"
          >
            <mat-label>{{
              'claim-management.dashboard.date_range' | translate
            }}</mat-label>
            <mat-date-range-input
              [rangePicker]="picker"
              gaCategoryGroup="ClaimsByStatusChart"
              gaClickListener="SelectDateRange"
            >
              <input
                matStartDate
                formControlName="from"
                readonly
                (click)="picker.open()"
              />
              <input
                matEndDate
                formControlName="to"
                readonly
                (click)="picker.open()"
              />
            </mat-date-range-input>

            <mat-date-range-picker
              (closed)="getClaims()"
              [calendarHeaderComponent]="DsPresetCalenderHeaderComponent"
              #picker
            ></mat-date-range-picker>

            <div matIconSuffix class="nowrap-text">
              <mat-datepicker-toggle [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker-toggle (click)="dateRange.reset(); getClaims()">
                <mat-icon matDatepickerToggleIcon>clear</mat-icon>
              </mat-datepicker-toggle>
            </div>
          </mat-form-field>
        </div>
        <div
          fxLayout="row"
          [fxLayoutGap]="1 | dsSpacing"
          *ngIf="!chartLoading; else pieChartLoading"
        >
          <ngx-charts-pie-chart
            *ngIf="chartData$ | async as chartData"
            fxLayout="column"
            [view]="view"
            [results]="chartData"
            [scheme]="$any(colorScheme)"
            [gradient]="false"
            [legend]="true"
            [legendTitle]="''"
            [legendPosition]="legendPosition"
            [labels]="false"
            [trimLabels]="false"
            [doughnut]="true"
            [arcWidth]="0.65"
            gaCategoryGroup="Dashboard"
            gaClickListener="ClaimsByStatusChartClick"
            (select)="goToClaimsByStatus($event, true)"
          >
          </ngx-charts-pie-chart>

          <ngx-charts-pie-chart
            fxLayout="column"
            *ngIf="chartDataArea?.length"
            [view]="view"
            [results]="chartDataArea"
            [scheme]="$any(colorScheme)"
            [gradient]="false"
            [legend]="true"
            [legendTitle]="''"
            [legendPosition]="legendPosition"
            [labels]="false"
            [trimLabels]="false"
            [doughnut]="true"
            [arcWidth]="0.65"
            gaCategoryGroup="Dashboard"
            gaClickListener="ClaimsByStatusAREAChartClick"
            (select)="goToClaimsByStatus($event, false)"
          >
          </ngx-charts-pie-chart>
          <br />
        </div>
      </mat-card-content>
    </mat-card>
  </div>

  <div fxLayout="row" fxLayout.lt-lg="column" [fxLayoutGap]="1 | dsSpacing">
    <cm-claims-by-period fxFlex></cm-claims-by-period>
    <cm-claims-by-product-line fxFlex></cm-claims-by-product-line>
  </div>

  <cm-accepted-declined></cm-accepted-declined>

  <mat-card>
    <mat-card-content>
      <cm-chart></cm-chart>
    </mat-card-content>
  </mat-card>

  <ng-template #loading>
    <mat-card>
      <mat-card-header>
        <mat-card-title>
          <ds-loading-placeholder
            [width]="10 | dsSpacing"
          ></ds-loading-placeholder>
        </mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <div *ngFor="let item of [1, 2, 3, 4, 5]">
          <ds-loading-placeholder
            [width]="20 | dsSpacing"
            [height]="2 | dsSpacing"
            class="h3"
          >
          </ds-loading-placeholder>
        </div>
      </mat-card-content>
    </mat-card>
  </ng-template>

  <ng-template #pieChartLoading>
    <div class="full-height" fxLayoutAlign="center center">
      <mat-progress-spinner
        [mode]="'indeterminate'"
        color="accent"
        [diameter]="100"
      >
      </mat-progress-spinner>
    </div>
  </ng-template>
</div>

<ng-template #noResult>
  <mat-card class="full-height">
    <mat-card-content>
      <ds-placeholder
        type="no_data"
        [customHeadline]="
          'claim-management.dashboard.no_new_claims' | translate
        "
      ></ds-placeholder>
    </mat-card-content>
  </mat-card>
</ng-template>
