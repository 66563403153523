import { DateAdapter } from '@angular/material/core';

export interface DsDatepickerPreset {
  title: string;
  calculateRange: (dateAdapter: DateAdapter<any>) => [Date, Date];
}

export const dsDefaultDateRangePresets: DsDatepickerPreset[] = [
  {
    title: 'datepicker_presets.this_week',
    calculateRange: (dateAdapter) => {
      const today = new Date();
      const deltaStart =
        dateAdapter.getFirstDayOfWeek() - dateAdapter.getDayOfWeek(today);
      const start = dateAdapter.addCalendarDays(today, deltaStart);
      const end = dateAdapter.addCalendarDays(start, 6);
      return [start, end];
    },
  },
  {
    title: 'datepicker_presets.last_week',
    calculateRange: (dateAdapter) => {
      const thisDayLastWeek = dateAdapter.addCalendarDays(new Date(), -7);
      const deltaStart =
        dateAdapter.getFirstDayOfWeek() -
        dateAdapter.getDayOfWeek(thisDayLastWeek);
      const start = dateAdapter.addCalendarDays(thisDayLastWeek, deltaStart);
      const end = dateAdapter.addCalendarDays(start, 6);
      return [start, end];
    },
  },
  {
    title: 'datepicker_presets.this_month',
    calculateRange: (dateAdapter) => {
      const today = new Date();
      const year = dateAdapter.getYear(today);
      const month = dateAdapter.getMonth(today);
      const start = dateAdapter.createDate(year, month, 1);
      const end = dateAdapter.addCalendarDays(
        start,
        dateAdapter.getNumDaysInMonth(today) - 1,
      );
      return [start, end];
    },
  },
  {
    title: 'datepicker_presets.last_month',
    calculateRange: (dateAdapter) => {
      const thisDayLastMonth = dateAdapter.addCalendarMonths(new Date(), -1);
      const year = dateAdapter.getYear(thisDayLastMonth);
      const month = dateAdapter.getMonth(thisDayLastMonth);
      const start = dateAdapter.createDate(year, month, 1);
      const end = dateAdapter.addCalendarDays(
        start,
        dateAdapter.getNumDaysInMonth(thisDayLastMonth) - 1,
      );
      return [start, end];
    },
  },
  {
    title: 'datepicker_presets.this_year',
    calculateRange: (dateAdapter) => {
      const year = dateAdapter.getYear(new Date());
      return [
        dateAdapter.createDate(year, 0, 1),
        dateAdapter.createDate(year, 11, 31),
      ];
    },
  },
  {
    title: 'datepicker_presets.last_year',
    calculateRange: (dateAdapter) => {
      const year = dateAdapter.getYear(new Date());
      return [
        dateAdapter.createDate(year - 1, 0, 1),
        dateAdapter.createDate(year - 1, 11, 31),
      ];
    },
  },
  {
    title: 'datepicker_presets.last_7_days',
    calculateRange: (dateAdapter) => [
      dateAdapter.addCalendarDays(new Date(), -6),
      dateAdapter.today(),
    ],
  },
  {
    title: 'datepicker_presets.last_30_days',
    calculateRange: (dateAdapter) => [
      dateAdapter.addCalendarDays(new Date(), -29),
      dateAdapter.today(),
    ],
  },
  {
    title: 'datepicker_presets.last_90_days',
    calculateRange: (dateAdapter) => [
      dateAdapter.addCalendarDays(new Date(), -89),
      dateAdapter.today(),
    ],
  },
  {
    title: 'datepicker_presets.last_365_days',
    calculateRange: (dateAdapter) => [
      dateAdapter.addCalendarDays(new Date(), -364),
      dateAdapter.today(),
    ],
  },
];
