import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FileDropDirective } from './file-drop.directive';

/**
 * #129664
 * Note: Needs to be discussed, if stil needed, could be replaced with DS compoennt
 * Consumed by: BPM, NDS, Service Inspection, Claim Management Lib, Sales Libs Project
 */
@NgModule({
  imports: [CommonModule],
  declarations: [FileDropDirective],
  exports: [FileDropDirective],
})
export class FileDropDirectiveModule {}
