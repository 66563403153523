/**
 * v1.1 - Claim Management API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1_claim_management
 * Contact: F.John@palfinger.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ExternalSparePartGet } from './externalSparePartGet';
import { CreditNote } from './creditNote';
import { ErrorCodingsDescriptions } from './errorCodingsDescriptions';
import { Attachment } from './attachment';
import { GetLabourRateResponse } from './getLabourRateResponse';
import { ErrorCodings } from './errorCodings';
import { InternalSparePartGet } from './internalSparePartGet';


export interface ClaimDetailGet { 
    
    repair_date: string;
    
    first_installation_date?: string;
    
    operation_hours?: number;
    
    claim_number: string;
    
    claim_number_area?: string;
    
    claim_type: ClaimDetailGetClaimType;
    
    fault_serial_number?: string;
    
    error_pattern?: string;
    
    repair_description?: string;
    
    error_codings: ErrorCodings;
    
    working_hours?: number;
    
    freight_allowance?: number;
    
    description_addition?: string;
    
    recall_action_template_id?: number;
    
    attachments?: Array<Attachment>;
    
    company_attachments?: Array<Attachment>;
    
    sap_attachments?: Array<Attachment>;
    
    claim_id: number;
    
    description?: string;
    
    claim_state: string;
    
    claim_state_enum: ClaimDetailGetClaimStateEnum;
    
    claim_display_mode: ClaimDetailGetClaimDisplayMode;
    
    error_coding_descriptions: ErrorCodingsDescriptions;
    
    working_hours_approved?: number;
    
    working_hour_price_approved?: number;
    
    processing_fee_approved?: number;
    
    processing_fee_decision?: string;
    
    freight_allowance_approved?: number;
    
    labour_rate: GetLabourRateResponse;
    
    created_on: string;
    
    created_by: string;
    
    modified_on: Date;
    /**
     * Either the full name (if available) or username of user who modified claim lastly
     */
    
    modified_by: string;
    
    decision?: string;
    
    claim_process?: ClaimDetailGetClaimProcess;
    
    delivery_note?: string;
    /**
     * Free text from GA requesting more information to be added to claim
     */
    
    required_information?: string;
    /**
     * Free text from SP, what information was added
     */
    
    additional_information?: string;
    
    is_decision_ga: boolean;
    
    sold_by_other_dealer: boolean;
    
    tracking_number?: string;
    
    selected_repair_id?: number;
    
    labour_hours_override_reason?: string;
    
    processing_fee_approved_state?: ClaimDetailGetProcessingFeeApprovedState;
    
    internal_spare_parts?: Array<InternalSparePartGet>;
    
    external_spare_parts?: Array<ExternalSparePartGet>;
    
    credit_notes?: Array<CreditNote>;
    
    readonly total_working_price: number;
    
    readonly total_working_price_approved: number;
    
    readonly total_internal_spare_parts_price: number;
    
    readonly total_internal_spare_parts_price_approved: number;
    
    readonly total_external_spare_parts_price: number;
    
    readonly total_external_spare_parts_price_approved: number;
    
    readonly total_price: number;
    
    readonly total_price_approved: number;
    
    is_recent: boolean;
    
    internal_note?: string;
    
    claim_display_state: ClaimDetailGetClaimDisplayState;
}
export enum ClaimDetailGetClaimType {
    BeforeDelivery = 'BeforeDelivery',
    WarrantyClaim = 'WarrantyClaim',
    FairDeal = 'FairDeal',
    LoadBearing = 'LoadBearing',
    SparePartClaim = 'SparePartClaim',
    ContractAgreement = 'ContractAgreement',
    RecallAction = 'RecallAction',
    ClaimOnDelivery = 'ClaimOnDelivery'
};
export enum ClaimDetailGetClaimStateEnum {
    DraftServicePartner = 'DraftServicePartner',
    DraftGeneralAgent = 'DraftGeneralAgent',
    DraftDecisionGeneralAgent = 'DraftDecisionGeneralAgent',
    SentToGeneralAgent = 'SentToGeneralAgent',
    SentToArea = 'SentToArea',
    InformationGivenToGeneralAgent = 'InformationGivenToGeneralAgent',
    ReturnedToServicePartner = 'ReturnedToServicePartner',
    ReturnedToGeneralAgent = 'ReturnedToGeneralAgent',
    PartsRequestedFromServicePartner = 'PartsRequestedFromServicePartner',
    PartsRequestedFromGeneralAgent = 'PartsRequestedFromGeneralAgent',
    PartsSentToGeneralAgent = 'PartsSentToGeneralAgent',
    PartsSentToArea = 'PartsSentToArea',
    AcceptedFullyByArea = 'AcceptedFullyByArea',
    AcceptedFullyByGeneralAgent = 'AcceptedFullyByGeneralAgent',
    AcceptedPartlyByArea = 'AcceptedPartlyByArea',
    AcceptedPartlyByGeneralAgent = 'AcceptedPartlyByGeneralAgent',
    DeclinedByArea = 'DeclinedByArea',
    DeclinedByGeneralAgent = 'DeclinedByGeneralAgent'
};
export enum ClaimDetailGetClaimDisplayMode {
    ReadOnly = 'ReadOnly',
    Edit = 'Edit',
    Returned = 'Returned',
    RecallAction = 'RecallAction',
    ReturnedToDecisionSP = 'ReturnedToDecisionSP'
};
export enum ClaimDetailGetClaimProcess {
    MaterialDelivery = 'MaterialDelivery',
    CreditNote = 'CreditNote'
};
export enum ClaimDetailGetProcessingFeeApprovedState {
    Accepted = 'Accepted',
    DeclinedMissingProductionData = 'DeclinedMissingProductionData',
    DeclinedIncompleteBasicData = 'DeclinedIncompleteBasicData',
    DeclinedMissingDataFile = 'DeclinedMissingDataFile',
    DeclinedSubmissionAfterDeadline = 'DeclinedSubmissionAfterDeadline',
    DeclinedMaterialIssue = 'DeclinedMaterialIssue',
    DeclinedAttachmentMissing = 'DeclinedAttachmentMissing',
    DeclinedTextAndCodingNotIdentical = 'DeclinedTextAndCodingNotIdentical',
    DeclinedOther = 'DeclinedOther'
};
export enum ClaimDetailGetClaimDisplayState {
    Draft = 'Draft',
    Sent = 'Sent',
    Returned = 'Returned',
    PartsRequested = 'PartsRequested',
    ReceivedFromSP = 'ReceivedFromSP',
    PartsRequestedFromSP = 'PartsRequestedFromSP',
    AcceptedFully = 'AcceptedFully',
    AcceptedPartially = 'AcceptedPartially',
    Declined = 'Declined',
    DecidedToSP = 'DecidedToSP',
    PartsSentFromSP = 'PartsSentFromSP',
    InformationRequestedFromSP = 'InformationRequestedFromSP',
    InformationGivenToGA = 'InformationGivenToGA'
};



