<mat-expansion-panel
  expanded="{{ !errorTypeDetailControl.value && !ReadOnlyMode }}"
  [disabled]="ReadOnlyMode"
  data-cy="error_codings_panel"
>
  <mat-expansion-panel-header>
    <mat-panel-title>
      <div class="title">
        <div>
          <span
            [ngClass]="
              !errorLocationControl.value || !errorLocationDetailControl.value
                ? 'warning-text'
                : ''
            "
            >{{
              ('claim-management.repairDescription.faultLocation' | translate) +
                errorLocationTitleText
            }}
          </span>
        </div>
        <div>
          <span
            [ngClass]="
              !errorLocationDetailControl.value || !errorTypeDetailControl.value
                ? 'warning-text fault-type-title'
                : 'fault-type-title'
            "
          >
            {{
              ('claim-management.repairDescription.faultType' | translate) +
                errorTypeTitleText
            }}</span
          >
        </div>
      </div>
    </mat-panel-title>
  </mat-expansion-panel-header>

  <div
    class="errorTree"
    fxLayout="row wrap"
    fxLayoutGap="1rem"
    fxLayoutGap.xs="0"
  >
    <div fxFlex>
      <mat-form-field class="tree-search">
        <mat-label>{{
          'claim-management.repairDescription.treeSearch_faultLocation'
            | translate
        }}</mat-label>
        <input
          id="leftSearchInput"
          type="text"
          matInput
          [formControl]="leftSearchTextControl"
          (input)="onLeftTreeSearchChange($event.target)"
        />
      </mat-form-field>
      <span matIconSuffix *ngIf="!errorCodingTree">
        <mat-progress-spinner
          [mode]="'indeterminate'"
          [diameter]="30"
          class="loading-spinner"
        ></mat-progress-spinner>
      </span>
      <ul>
        <li
          [ngClass]="{
            selected_light: errorLocationControl.value === node.sap_code
          }"
          *ngFor="let node of errorCodingTree"
          [attr.data-cy]="'error_location_node_' + node.sap_code"
        >
          <div
            (click)="onTreeSelectedItem(0, node)"
            fxLayout="row"
            fxLayoutAlign="start center"
          >
            <button mat-icon-button>
              <mat-icon class="mat-icon-rtl-mirror">
                {{
                  errorLocationControl.value === node.sap_code
                    ? 'expand_more'
                    : 'chevron_right'
                }}
              </mat-icon>
            </button>
            <span
              [innerHtml]="
                node.sap_code | highlight: leftSearchTextControl.value
              "
            >
            </span
            >&nbsp;-&nbsp;<span
              [innerHtml]="
                node.description | highlight: leftSearchTextControl.value
              "
            ></span>
          </div>
          <ul *ngIf="errorLocationControl.value === node.sap_code">
            <li
              *ngFor="let childnode of node.children"
              (click)="onTreeSelectedItem(1, childnode)"
              [attr.data-cy]="
                'error_location_detail_node_' + childnode.sap_code
              "
            >
              <div
                class="list-entry"
                [ngClass]="{
                  neutral:
                    errorLocationDetailControl.value === childnode.sap_code &&
                    selectedDescriptions.error_location_detail_description ===
                      childnode.description
                }"
              >
                <span
                  [innerHtml]="
                    childnode.sap_code | highlight: leftSearchTextControl.value
                  "
                >
                </span
                ><span> - </span
                ><span
                  [innerHtml]="
                    childnode.description
                      | highlight: leftSearchTextControl.value
                  "
                ></span>
              </div>
            </li>
          </ul>
        </li>
      </ul>
    </div>
    <div fxFlex>
      <mat-form-field class="tree-search" *ngIf="thirdLevelNodes">
        <mat-label>{{
          'claim-management.repairDescription.treeSearch_faultType' | translate
        }}</mat-label>
        <input
          id="rightSearchInput"
          type="text"
          matInput
          [formControl]="rightSearchTextControl"
          (input)="onRightTreeSearchChange($event.target)"
        />
      </mat-form-field>
      <ul>
        <li
          [ngClass]="{
            selected_light: errorTypeControl.value === node.sap_code
          }"
          *ngFor="let node of thirdLevelNodes"
          [attr.data-cy]="'error_type_node_' + node.sap_code"
        >
          <div
            fxLayout="row"
            fxLayoutAlign="start center"
            (click)="onTreeSelectedItem(2, node)"
          >
            <button mat-icon-button>
              <mat-icon class="mat-icon-rtl-mirror">
                {{
                  errorTypeControl.value === node.sap_code
                    ? 'expand_more'
                    : 'chevron_right'
                }}
              </mat-icon>
            </button>
            <span
              [innerHtml]="
                node.sap_code | highlight: rightSearchTextControl.value
              "
            >
            </span>
            <ng-container *ngIf="node.description">
              &nbsp;-&nbsp;<span
                [innerHtml]="
                  node.description | highlight: rightSearchTextControl.value
                "
              ></span>
            </ng-container>
          </div>
          <ul *ngIf="errorTypeControl.value === node.sap_code">
            <li
              *ngFor="let childnode of node.children"
              (click)="onTreeSelectedItem(3, childnode)"
              [attr.data-cy]="'error_type_detail_node_' + childnode.sap_code"
            >
              <div
                class="list-entry"
                [ngClass]="{
                  neutral:
                    errorTypeDetailControl.value === childnode.sap_code &&
                    selectedDescriptions.error_type_detail_description ===
                      childnode.description
                }"
              >
                <span
                  [innerHtml]="
                    childnode.sap_code | highlight: rightSearchTextControl.value
                  "
                >
                </span>
                <ng-container *ngIf="childnode.description">
                  <span> - </span
                  ><span
                    [innerHtml]="
                      childnode.description
                        | highlight: rightSearchTextControl.value
                    "
                  ></span>
                </ng-container>
              </div>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </div>
</mat-expansion-panel>
