/**
 * v1.1 - Claim Management API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1_claim_management
 * Contact: F.John@palfinger.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface SetClaimStatus { 
    
    claim_id: number;
    
    local_date: string;
    
    status_action: SetClaimStatusStatusAction;
}
export enum SetClaimStatusStatusAction {
    Reopen = 'Reopen',
    PartReceivedFromSp = 'PartReceivedFromSp',
    InformationReceived = 'InformationReceived'
};



