<mat-card fxLayout="column" class="full-height">
  <mat-card-header>
    <mat-card-title class="no-margin"
      >{{ 'claim-management.dashboard.claims_by_product_line' | translate }}
    </mat-card-title>
  </mat-card-header>
  <mat-card-content fxFlex fxLayout="column" fxLayoutAlign="top">
    <mat-form-field [formGroup]="dateRange">
      <mat-label>{{
        'claim-management.dashboard.date_range' | translate
      }}</mat-label>
      <mat-date-range-input
        [rangePicker]="picker"
        gaCategoryGroup="ClaimsByProductLineChart"
        gaClickListener="SelectDateRange"
      >
        <input
          matStartDate
          formControlName="from"
          readonly
          (click)="picker.open()"
        />
        <input
          matEndDate
          formControlName="to"
          readonly
          (click)="picker.open()"
        />
      </mat-date-range-input>
      <mat-date-range-picker
        (closed)="getData()"
        [calendarHeaderComponent]="DsPresetCalenderHeaderComponent"
        #picker
      ></mat-date-range-picker>

      <div matIconSuffix class="nowrap-text">
        <mat-datepicker-toggle [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker-toggle (click)="dateRange.reset(); getData()">
          <mat-icon matDatepickerToggleIcon>clear</mat-icon>
        </mat-datepicker-toggle>
      </div>
    </mat-form-field>
    <ng-container
      *ngIf="
        claimsByProductLine$ | async as claimsByProductLine;
        else pieChartLoading
      "
    >
      <ngx-charts-pie-chart
        *ngIf="claimsByProductLine.length; else noData"
        [view]="view"
        [results]="claimsByProductLine"
        [scheme]="$any(colorScheme)"
        [gradient]="false"
        [legend]="true"
        [legendTitle]="''"
        [legendPosition]="legendPosition"
        [labels]="false"
        [trimLabels]="false"
        [doughnut]="true"
        [arcWidth]="0.65"
        gaCategoryGroup="Dashboard"
        gaClickListener="ClaimsByProductLineChartClick"
        (select)="goToClaimsByProductLine($event)"
      >
      </ngx-charts-pie-chart>
    </ng-container>
  </mat-card-content>
</mat-card>

<ng-template #pieChartLoading>
  <div class="full-height" fxLayoutAlign="center center">
    <mat-progress-spinner
      [mode]="'indeterminate'"
      color="accent"
      [diameter]="100"
    >
    </mat-progress-spinner>
  </div>
</ng-template>

<ng-template #noData>
  <div class="full-height" fxLayoutAlign="center center">
    <ds-placeholder [type]="'no_data'"></ds-placeholder>
  </div>
</ng-template>
