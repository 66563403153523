/**
 * v1.1 - Claim Management API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1_claim_management
 * Contact: F.John@palfinger.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ValidateSapNumberResponse { 
    
    result: ValidateSapNumberResponseResult;
    
    formatted_sap_number?: string;
}
export enum ValidateSapNumberResponseResult {
    Valid = 'Valid',
    Invalid = 'Invalid',
    EquipmentNotMatching = 'EquipmentNotMatching',
    ClaimDoneForDifferentRecall = 'ClaimDoneForDifferentRecall'
};



