import { COMMA, ENTER, SEMICOLON } from '@angular/cdk/keycodes';
import { Component, Input, ViewChild, forwardRef } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
} from '@angular/forms';
import { MatChipGrid, MatChipInputEvent } from '@angular/material/chips';

@Component({
  selector: 'paldesk-email-addresses',
  templateUrl: './email-addresses.component.html',
  styleUrls: ['./email-addresses.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EmailAddressesComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => EmailAddressesComponent),
      multi: true,
    },
  ],
})
export class EmailAddressesComponent
  implements ControlValueAccessor, Validator
{
  @Input() nonRemovableEmails: string[] = [];
  @Input() labelKey = 'email_addresses.email_addresses';
  @ViewChild(MatChipGrid) chipList: MatChipGrid;
  readonly separatorKeysCodes = [ENTER, COMMA, SEMICOLON];
  emailList: string[];
  required = false;
  disabled = false;

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(value?: string[]): void {
    if (value) {
      this.emailList = value;
      return;
    }

    this.emailList = [];
  }

  // eslint-disable-next-line
  propagateChange(_: string[]) {}

  onChange() {
    this.propagateChange(this.emailList);
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onTouched: any = () => {};

  registerOnChange(fn: any) {
    this.propagateChange = fn;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  registerOnTouched(fn: any): void {
    return;
  }

  removeEmail(email: string): void {
    if (this.disabled) {
      return;
    }

    const filteredList = this.emailList.filter((x) => x !== email);
    this.writeValue(filteredList);
    if (this.required && filteredList.length === 0) {
      this.chipList.errorState = true;
    }
    this.onChange();
  }

  validate(c: AbstractControl): ValidationErrors | null {
    if (c.validator) {
      const validator = c.validator({} as FormControl);
      this.required = validator && validator['required'];
    }

    if (this.chipList?.errorState) {
      return {
        invalidEmailAddress: true,
      };
    }

    return null;
  }

  addEmail(event: MatChipInputEvent): void {
    if (this.disabled) {
      return;
    }

    const emailAddress = event.value.trim();
    // ignore empty values
    if (emailAddress.length === 0) {
      return;
    }
    const regex = new RegExp('^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$');

    if (regex.test(emailAddress) && !this.emailList.includes(emailAddress)) {
      this.chipList.errorState = false;
      this.writeValue([...this.emailList, emailAddress]);
      event.chipInput?.clear();
    } else {
      this.chipList.errorState = true;
    }
    this.onChange();
  }
}
