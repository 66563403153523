import { CurrencyPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatChipsModule } from '@angular/material/chips';
import { RouterModule } from '@angular/router';
import { StorePageSizeDirectiveModule } from '@cdk/store-page-size';
import { ClaimService } from '@claim-management-lib/data-access';
import { SharedModule } from '@claim-management-lib/feat-claim-shared';
import { DsAdvancedDatepickerModule } from '@design-system/components/advanced-datepicker';
import { DsLoadingModule } from '@design-system/components/loading';
import { DsTextIndicatorModule } from '@design-system/components/text-indicator';
import { DsPageModule } from '@design-system/feature/page';
import { DsSpotlightModule } from '@design-system/feature/spotlight';
import { SharedLibFeaturesEmailListModule } from '@paldesk/shared-lib/features/email-list';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { AcceptedDeclinedTooltipComponent } from './accepted-declined/accepted-declined-tooltip/accepted-declined-tooltip.component';
import { AcceptedDeclinedComponent } from './accepted-declined/accepted-declined.component';
import { ChartComponent } from './chart/chart.component';
import { ClaimsByPeriodComponent } from './claims-by-period/claims-by-period.component';
import { ClaimsByProductLineComponent } from './claims-by-product-line/claims-by-product-line.component';
import { DashboardComponent } from './dashboard.component';
import { NotificationSettingsComponent } from './notification-settings/notification-settings.component';
import { CustomPeriodsDialogComponent } from './claims-by-period/custom-periods-dialog-component/custom-periods-dialog-component.component';

@NgModule({
  declarations: [
    DashboardComponent,
    ChartComponent,
    AcceptedDeclinedComponent,
    ClaimsByPeriodComponent,
    ClaimsByProductLineComponent,
    NotificationSettingsComponent,
    AcceptedDeclinedTooltipComponent,
    CustomPeriodsDialogComponent,
  ],
  imports: [
    DsAdvancedDatepickerModule,
    DsLoadingModule,
    DsPageModule,
    DsSpotlightModule,
    DsTextIndicatorModule,
    NgxChartsModule,
    MatChipsModule,
    SharedModule,
    SharedLibFeaturesEmailListModule,
    StorePageSizeDirectiveModule,
    RouterModule,
  ],
  providers: [ClaimService, CurrencyPipe],
})
export class DashboardModule {}
