import { Component, Input } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { MatDateRangePicker } from '@angular/material/datepicker';
import { DsDatepickerPreset } from '../datepicker-presets';

@Component({
  selector: 'ds-custom-range-panel',
  templateUrl: './custom-range-panel.component.html',
  styleUrls: ['./custom-range-panel.component.css'],
})
export class CustomRangePanelComponent {
  @Input() presets?: DsDatepickerPreset[];

  constructor(
    private picker: MatDateRangePicker<Date>,
    private dateAdapter: DateAdapter<any>,
  ) {}

  selectRange(preset: DsDatepickerPreset): void {
    const [start, end] = preset.calculateRange(this.dateAdapter);
    this.picker.select(start);
    this.picker.select(end);
    this.picker.close();
  }
}
