/**
 * v1.1 - Claim Management API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1_claim_management
 * Contact: F.John@palfinger.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Data displayed on claimdesk for decision general agents
 */
export interface ClaimdeskDecisionRow { 
    
    claim_id: number;
    
    claim_type: string;
    
    claim_number?: string;
    
    claim_number_sp?: string;
    
    claim_number_area?: string;
    
    repair_date?: string;
    
    created_on?: string;
    
    equipment_number: string;
    
    state_sp_to_ga?: ClaimdeskDecisionRowStateSpToGa;
    
    state_area_ga?: ClaimdeskDecisionRowStateAreaGa;
    
    product_type: string;
    
    service_partner_name?: string;
    
    delivery_note?: string;
    
    total_sp: number;
    
    total_approved_ga: number;
    
    total_ga: number;
    
    total_approved_area: number;
    
    can_delete: boolean;
    
    navigation_type: ClaimdeskDecisionRowNavigationType;
    
    decision_date?: Date;
    
    decision_date_area?: Date;
    
    license_plate?: string;
    
    requested_processing_fee_ga: number;
    
    requested_processing_fee_sp: number;
    
    approved_processing_fee_ga: number;
    
    approved_processing_fee_area: number;
    
    approved_processing_fee_state_area?: ClaimdeskDecisionRowApprovedProcessingFeeStateArea;
    
    approved_processing_fee_state_ga?: ClaimdeskDecisionRowApprovedProcessingFeeStateGa;
    
    credit_notes?: string;
    
    credit_notes_area?: string;
    
    is_recent: boolean;
    
    internal_note?: string;
    
    sibling_count: number;
    
    submitted_to_ga_on?: Date;
    /**
     * The number of days since the SP was last requested to provide additional parts for the claim.  This field is populated only if the last claim state involves PartsRequestedFromServicePartner, otherwise field is set to null.
     */
    
    days_since_material_requested?: number;
    /**
     * The number of days since the SP was last requested to provide additional information for the claim.  This field is populated only if the last claim state involves ReturnedToServicePartner, otherwise field is set to null.
     */
    
    days_since_info_requested?: number;
}
export enum ClaimdeskDecisionRowStateSpToGa {
    Draft = 'Draft',
    Sent = 'Sent',
    Returned = 'Returned',
    PartsRequested = 'PartsRequested',
    ReceivedFromSP = 'ReceivedFromSP',
    PartsRequestedFromSP = 'PartsRequestedFromSP',
    AcceptedFully = 'AcceptedFully',
    AcceptedPartially = 'AcceptedPartially',
    Declined = 'Declined',
    DecidedToSP = 'DecidedToSP',
    PartsSentFromSP = 'PartsSentFromSP',
    InformationRequestedFromSP = 'InformationRequestedFromSP',
    InformationGivenToGA = 'InformationGivenToGA'
};
export enum ClaimdeskDecisionRowStateAreaGa {
    Draft = 'Draft',
    Sent = 'Sent',
    Returned = 'Returned',
    PartsRequested = 'PartsRequested',
    ReceivedFromSP = 'ReceivedFromSP',
    PartsRequestedFromSP = 'PartsRequestedFromSP',
    AcceptedFully = 'AcceptedFully',
    AcceptedPartially = 'AcceptedPartially',
    Declined = 'Declined',
    DecidedToSP = 'DecidedToSP',
    PartsSentFromSP = 'PartsSentFromSP',
    InformationRequestedFromSP = 'InformationRequestedFromSP',
    InformationGivenToGA = 'InformationGivenToGA'
};
export enum ClaimdeskDecisionRowNavigationType {
    Edit = 'Edit',
    Decision = 'Decision',
    Delivery = 'Delivery',
    DecisionDelivery = 'DecisionDelivery'
};
export enum ClaimdeskDecisionRowApprovedProcessingFeeStateArea {
    Accepted = 'Accepted',
    DeclinedMissingProductionData = 'DeclinedMissingProductionData',
    DeclinedIncompleteBasicData = 'DeclinedIncompleteBasicData',
    DeclinedMissingDataFile = 'DeclinedMissingDataFile',
    DeclinedSubmissionAfterDeadline = 'DeclinedSubmissionAfterDeadline',
    DeclinedMaterialIssue = 'DeclinedMaterialIssue',
    DeclinedAttachmentMissing = 'DeclinedAttachmentMissing',
    DeclinedTextAndCodingNotIdentical = 'DeclinedTextAndCodingNotIdentical',
    DeclinedOther = 'DeclinedOther'
};
export enum ClaimdeskDecisionRowApprovedProcessingFeeStateGa {
    Accepted = 'Accepted',
    DeclinedMissingProductionData = 'DeclinedMissingProductionData',
    DeclinedIncompleteBasicData = 'DeclinedIncompleteBasicData',
    DeclinedMissingDataFile = 'DeclinedMissingDataFile',
    DeclinedSubmissionAfterDeadline = 'DeclinedSubmissionAfterDeadline',
    DeclinedMaterialIssue = 'DeclinedMaterialIssue',
    DeclinedAttachmentMissing = 'DeclinedAttachmentMissing',
    DeclinedTextAndCodingNotIdentical = 'DeclinedTextAndCodingNotIdentical',
    DeclinedOther = 'DeclinedOther'
};



