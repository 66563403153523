import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import {
  DashboardService,
  PeriodStatisticsClaim,
  PeriodStatisticsClaimNavigationType,
  RecentClaimNavigationType,
} from '@claim-management-lib/data-access';
import { api_version } from '@claim-management-lib/feat-claim-shared';
import { DsPresetCalenderHeaderComponent } from '@design-system/components/advanced-datepicker';
import { DateUtils } from '@paldesk/shared-lib/utils/date-utils';
import { combineLatest, Observable, Subject } from 'rxjs';
import { map, startWith, takeUntil } from 'rxjs/operators';
import { GoogleAnalytics4Service } from '@shared-lib/google-analytics';

interface BubbleChartData {
  name: string;
  claim_id: number;
  x?: Date;
  y: number;
  r: number;
  currency: string;
  navigationType:
    | RecentClaimNavigationType
    | PeriodStatisticsClaimNavigationType;
}

enum DataType {
  ReturnSubmit = 'ReturnSubmit',
  RepairSubmit = 'RepairSubmit',
}

@Component({
  selector: 'cm-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.scss'],
})
export class ChartComponent implements OnInit, OnDestroy {
  filterForm: FormGroup<{
    dateRange: FormGroup;
    dayDiffThreshold: FormControl<number>;
    dataType: FormControl<DataType>;
  }>;
  today = new Date();
  DsPresetCalenderHeaderComponent = DsPresetCalenderHeaderComponent;

  results$: Observable<
    { name: string; series: BubbleChartData[] }[] | undefined
  >;

  dataType = DataType;
  defaultDayDiffThreshold = 6;
  yAxisMax = 0;

  private readonly destroy$ = new Subject<void>();

  constructor(
    private ga4service: GoogleAnalytics4Service,
    private fb: FormBuilder,
    private dashboardService: DashboardService,
    private dateAdapter: DateAdapter<any>,
  ) {
    this.filterForm = this.fb.group({
      dateRange: this.fb.group({
        startDate: [this.dateAdapter.addCalendarDays(this.today, -89)],
        endDate: [this.today],
      }),
      dayDiffThreshold: new FormControl(this.defaultDayDiffThreshold, {
        nonNullable: true,
      }),
      dataType: new FormControl(DataType.ReturnSubmit, { nonNullable: true }),
    });
  }

  get dateRange(): FormGroup {
    return this.filterForm.get('dateRange') as FormGroup;
  }

  ngOnInit(): void {
    combineLatest([
      this.filterForm
        .get('dayDiffThreshold')
        ?.valueChanges.pipe(startWith(this.defaultDayDiffThreshold)),
      this.filterForm
        .get('dataType')
        ?.valueChanges.pipe(startWith(DataType.ReturnSubmit)),
    ])
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.getChartData();
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  getChartData(): void {
    const formValue = this.filterForm.getRawValue();

    const start = this.dateRange.value.startDate;
    const end = this.dateRange.value.endDate;

    const serviceCall =
      formValue.dataType === DataType.ReturnSubmit
        ? this.dashboardService.getReturnedSentStatistics(
            api_version,
            start ? new Date(DateUtils.toISODateStringLocal(start)) : undefined,
            end ? new Date(DateUtils.toISODateStringLocal(end)) : undefined,
            formValue.dayDiffThreshold,
          )
        : this.dashboardService.getRepairedSentStatistics(
            api_version,
            start ? new Date(DateUtils.toISODateStringLocal(start)) : undefined,
            end ? new Date(DateUtils.toISODateStringLocal(end)) : undefined,
            formValue.dayDiffThreshold,
          );
    this.results$ = serviceCall.pipe(
      map((data) => {
        const gaChartData = this.convertToBubbleChartData(
          data.general_agent_claims,
          data.general_agent_currency,
        );
        const spChartData = this.convertToBubbleChartData(
          data.service_partner_claims,
          data.service_partner_currency,
        );
        // tooltip doesn't show for values on the edge -> extend y-axis by 5%
        this.yAxisMax =
          Math.max(
            ...gaChartData.map((bubble) => bubble.y),
            ...spChartData.map((bubble) => bubble.y),
          ) * 1.05;
        return [
          {
            name: 'GA Claims',
            series: gaChartData,
          },
          {
            name: 'SP Claims',
            series: spChartData,
          },
        ];
      }),
    );
  }

  onBubbleClick(event: any): void {
    if (event.claim_id) {
      this.ga4service.event('NavigateToClaimByBubbleClick', {
        category: 'Dashboard',
      });
      window.open(
        this.getClaimLinkByNavigationType(event.navigationType) +
          event.claim_id,
        '_blank',
      );
    }
  }

  getClaimLinkByNavigationType(
    navigation_type: RecentClaimNavigationType,
  ): string {
    switch (navigation_type) {
      case RecentClaimNavigationType.Decision:
        return '/decision/';
      case RecentClaimNavigationType.Delivery:
        return '/delivery/';
      case RecentClaimNavigationType.Edit:
        return '/claim/edit/';
      default:
        return '';
    }
  }

  private convertToBubbleChartData(
    data?: PeriodStatisticsClaim[],
    currency?: string,
  ): BubbleChartData[] {
    return (
      data?.map((x) => ({
        name: x.claim_number,
        claim_id: x.claim_id,
        x: DateUtils.parseISODateString(x.date),
        y: x.day_diff,
        r: x.amount,
        currency: currency || '',
        navigationType: x.navigation_type,
      })) || []
    );
  }
}
