/// Compares material numbers (ignoring casing and ignoring spaces)
export class MaterialNumberComparer {
  static compare(oldVal: any, newVal: any): boolean {
    if (oldVal === newVal) {
      return true;
    }
    if (
      oldVal &&
      newVal &&
      (oldVal as string).toUpperCase().replace(/ /g, '') ===
        (newVal as string).toUpperCase().replace(/ /g, '')
    ) {
      return true;
    }
    return false;
  }
}
