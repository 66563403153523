/**
 * v1.1 - Claim Management API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1_claim_management
 * Contact: F.John@palfinger.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { UndoneRecalls } from './undoneRecalls';


export interface GetClaimTypesResponse { 
    
    available_claim_types: Array<GetClaimTypesResponseAvailableClaimTypes>;
    
    available_recalls?: Array<UndoneRecalls>;
}
export enum GetClaimTypesResponseAvailableClaimTypes {
    BeforeDelivery = 'BeforeDelivery',
    WarrantyClaim = 'WarrantyClaim',
    FairDeal = 'FairDeal',
    LoadBearing = 'LoadBearing',
    SparePartClaim = 'SparePartClaim',
    ContractAgreement = 'ContractAgreement',
    RecallAction = 'RecallAction',
    ClaimOnDelivery = 'ClaimOnDelivery'
};



