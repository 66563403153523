/**
 * v1.1 - Claim Management API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1_claim_management
 * Contact: F.John@palfinger.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Attachment } from './attachment';
import { DeliverySparePart } from './deliverySparePart';


export interface DeliveryClaimSend { 
    
    delivery_note: string;
    
    delivery_type: DeliveryClaimSendDeliveryType;
    
    delivery_claim_reason: DeliveryClaimSendDeliveryClaimReason;
    
    claim_number: string;
    
    description: string;
    
    local_user_date: Date;
    
    equipment_number?: string;
    
    internal_note?: string;
    
    spare_parts?: Array<DeliverySparePart>;
    
    attachments?: Array<Attachment>;
    
    company_attachments?: Array<Attachment>;
}
export enum DeliveryClaimSendDeliveryType {
    Palfinger = 'Palfinger',
    GeneralAgent = 'GeneralAgent'
};
export enum DeliveryClaimSendDeliveryClaimReason {
    MissingParts = 'MissingParts',
    WrongPartsDelivered = 'WrongPartsDelivered',
    OverDelivery = 'OverDelivery',
    QualityIssueOrDamage = 'QualityIssueOrDamage',
    TransportDamage = 'TransportDamage'
};



