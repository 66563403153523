/**
 * v1.1 - Claim Management API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1_claim_management
 * Contact: F.John@palfinger.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface RecallActionTemplateEquipmentGet { 
    
    equipment_number: string;
    
    comment?: string;
    
    sap_number?: string;
    
    general_agent_country?: string;
    
    general_agent_name?: string;
    
    equipment_serial_number?: string;
    
    state: RecallActionTemplateEquipmentGetState;
    
    claim_created_on?: Date;
}
export enum RecallActionTemplateEquipmentGetState {
    Available = 'Available',
    MarkedAsDone = 'MarkedAsDone',
    ClaimSubmitted = 'ClaimSubmitted',
    SubmittedToGeneralAgent = 'SubmittedToGeneralAgent',
    SapSubmitError = 'SapSubmitError'
};



