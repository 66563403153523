<ng-container
  *pdLet="chartData$ | async as results"
  gaCategoryGroup="DashboardAcceptanceRatio"
>
  <div
    fxLayout="row"
    fxLayout.lt-lg="column"
    [fxLayoutGap]="1 | dsSpacing"
    *ngIf="partnerType"
  >
    <mat-card fxFlex="50%" fxFlex.lt-lg="auto">
      <mat-card-header
        fxLayout="row"
        fxLayout.lt-md="column"
        fxLayoutAlign="space-between baseline"
        fxLayoutAlign.lt-md="start"
        class="no-margin"
      >
        <strong matCardTitle>
          {{ 'claim-management.dashboard.total_accepted_ratio' | translate }}
        </strong>
        <mat-form-field
          *ngIf="showSelector"
          [fxFlex]="4 | dsSpacing"
          [subscriptSizing]="'dynamic'"
        >
          <mat-select [(ngModel)]="partnerType" gaClickListener="SelectSPvsGA">
            <mat-option [value]="'sp'">SP</mat-option>
            <mat-option [value]="'ga'">GA</mat-option>
          </mat-select>
        </mat-form-field>
      </mat-card-header>
      <mat-card-content>
        <div
          *ngIf="results?.total_amount_data as data; else chartLoading"
          [ngStyle]="{ height: 20 | dsSpacing, width: '100%' }"
        >
          <ngx-charts-bar-vertical-stacked
            [results]="partnerType === 'ga' ? data.ga : data.sp"
            [legend]="false"
            [xAxis]="true"
            [yAxis]="true"
            [scheme]="$any(colorSchemeTotal)"
            [showDataLabel]="false"
            [yAxisTickFormatting]="yAxisTickFormatting"
            (select)="goToClaimsByAcceptanceRatio($event, true)"
          >
            <ng-template #tooltipTemplate let-claim="model">
              <cm-accepted-declined-tooltip
                [name]="claim.name"
                [currency]="partnerType === 'ga' ? gaCurrency : spCurrency"
                [value]="claim.value"
                [total]="claim.total"
              ></cm-accepted-declined-tooltip>
            </ng-template>
          </ngx-charts-bar-vertical-stacked>
        </div>
      </mat-card-content>
    </mat-card>
    <mat-card fxFlex="50%" fxFlex.lt-lg="auto">
      <mat-card-header
        fxLayout="row"
        fxLayout.lt-md="column"
        fxLayoutAlign="space-between baseline"
        fxLayoutAlign.lt-md="start"
        class="no-margin"
      >
        <strong matCardTitle>
          {{
            'claim-management.dashboard.accepted_handling_lump_sum_ratio'
              | translate
          }}
          <small>
            <div
              [ngClass]="{
                'error-text':
                  partnerType === 'sp'
                    ? spProcessingFeeDeclineRatio >= processingFeeDeclineGoal
                    : gaProcessingFeeDeclineRatio >= processingFeeDeclineGoal,
                'success-text':
                  partnerType === 'sp'
                    ? spProcessingFeeDeclineRatio < processingFeeDeclineGoal
                    : gaProcessingFeeDeclineRatio < processingFeeDeclineGoal
              }"
            >
              {{
                'claim-management.dashboard.declined_goal'
                  | translate
                    : {
                        declineRatio:
                          ((partnerType === 'sp'
                            ? spProcessingFeeDeclineRatio
                            : gaProcessingFeeDeclineRatio
                          ) | number: '1.0-2'),
                        goalRatio: processingFeeDeclineGoal
                      }
              }}
            </div></small
          ></strong
        >
        <div fxLayout="row" fxLayoutAlign="start center">
          <mat-slide-toggle
            [formControl]="showDetailedViewControl"
            labelPosition="before"
            class="detailed-view-toggle"
          >
            {{ 'claim-management.dashboard.detailed_view' | translate }}
          </mat-slide-toggle>
          <mat-form-field
            *ngIf="showSelector"
            [fxFlex]="4 | dsSpacing"
            [subscriptSizing]="'dynamic'"
          >
            <mat-select
              [(ngModel)]="partnerType"
              gaClickListener="SelectSPvsGA"
            >
              <mat-option [value]="'sp'">SP</mat-option>
              <mat-option [value]="'ga'">GA</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </mat-card-header>
      <mat-card-content>
        <div
          *ngIf="results?.service_fee_data as data; else chartLoading"
          [ngStyle]="{ height: 20 | dsSpacing, width: '100%' }"
        >
          <ngx-charts-bar-vertical-stacked
            [results]="partnerType === 'ga' ? data.ga : data.sp"
            [legend]="false"
            [xAxis]="true"
            [yAxis]="true"
            [scheme]="$any(colorSchemeServiceFee)"
            [showDataLabel]="false"
            [yAxisTickFormatting]="yAxisTickFormatting"
            (select)="goToClaimsByAcceptanceRatio($event, false)"
          >
            <ng-template #tooltipTemplate let-claim="model">
              <cm-accepted-declined-tooltip
                [name]="claim.name"
                [currency]="partnerType === 'ga' ? gaCurrency : spCurrency"
                [value]="claim.value"
                [total]="claim.total"
              ></cm-accepted-declined-tooltip>
            </ng-template>
          </ngx-charts-bar-vertical-stacked>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</ng-container>

<ng-template #chartLoading>
  <div [ngStyle]="{ height: 25 | dsSpacing, position: 'relative' }">
    <mat-progress-spinner
      color="accent"
      [diameter]="100"
      [mode]="'indeterminate'"
      class="loading-spinner"
    ></mat-progress-spinner>
  </div>
</ng-template>

<ng-template #noData>
  <ds-placeholder [type]="'no_data'"></ds-placeholder>
</ng-template>
