/**
 * v1.1 - Claim Management API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1_claim_management
 * Contact: F.John@palfinger.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { GetMaterialInfoResponse } from './getMaterialInfoResponse';


export interface GetMaterialInformationResponse { 
    
    material_info_dto?: GetMaterialInfoResponse;
    
    material_information_result: GetMaterialInformationResponseMaterialInformationResult;
}
export enum GetMaterialInformationResponseMaterialInformationResult {
    Valid = 'Valid',
    Invalid = 'Invalid'
};



