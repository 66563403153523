import { FormControl } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';

/**
 * Custom ErrorStateMatcher which returns true (error exists) when the parent form group is invalid and the control has been touched
 */
export class ValidParentErrorStateMatcher implements ErrorStateMatcher {
  constructor(
    private requireTouched = true,
    private requireDirty = false,
  ) {}

  isErrorState(control: FormControl): boolean {
    if (control.parent) {
      return (
        control.parent.invalid &&
        (!this.requireTouched || control.touched) &&
        (!this.requireDirty || control.dirty)
      );
    }
    return false;
  }
}
