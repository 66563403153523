/**
 * v1.1 - Claim Management API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1_claim_management
 * Contact: F.John@palfinger.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface RecentClaim { 
    
    claim_id: number;
    
    state?: RecentClaimState;
    
    state_area?: RecentClaimStateArea;
    
    claim_number: string;
    
    date_of_change: Date;
    
    navigation_type: RecentClaimNavigationType;
}
export enum RecentClaimState {
    Draft = 'Draft',
    Sent = 'Sent',
    Returned = 'Returned',
    PartsRequested = 'PartsRequested',
    ReceivedFromSP = 'ReceivedFromSP',
    PartsRequestedFromSP = 'PartsRequestedFromSP',
    AcceptedFully = 'AcceptedFully',
    AcceptedPartially = 'AcceptedPartially',
    Declined = 'Declined',
    DecidedToSP = 'DecidedToSP',
    PartsSentFromSP = 'PartsSentFromSP',
    InformationRequestedFromSP = 'InformationRequestedFromSP',
    InformationGivenToGA = 'InformationGivenToGA'
};
export enum RecentClaimStateArea {
    Draft = 'Draft',
    Sent = 'Sent',
    Returned = 'Returned',
    PartsRequested = 'PartsRequested',
    ReceivedFromSP = 'ReceivedFromSP',
    PartsRequestedFromSP = 'PartsRequestedFromSP',
    AcceptedFully = 'AcceptedFully',
    AcceptedPartially = 'AcceptedPartially',
    Declined = 'Declined',
    DecidedToSP = 'DecidedToSP',
    PartsSentFromSP = 'PartsSentFromSP',
    InformationRequestedFromSP = 'InformationRequestedFromSP',
    InformationGivenToGA = 'InformationGivenToGA'
};
export enum RecentClaimNavigationType {
    Edit = 'Edit',
    Decision = 'Decision',
    Delivery = 'Delivery',
    DecisionDelivery = 'DecisionDelivery'
};



