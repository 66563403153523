import { Injectable } from '@angular/core';
import {
  GetUserContextResponse,
  PermissionService,
} from '@claim-management-lib/data-access';
import { Observable, shareReplay } from 'rxjs';
import { api_version } from '../constants';

@Injectable({ providedIn: 'root' })
export class AccessService {
  private readonly claimUserContext$: Observable<GetUserContextResponse>;

  constructor(private permissionService: PermissionService) {
    this.claimUserContext$ = this.permissionService
      .getClaimUserContext(api_version)
      .pipe(shareReplay());
  }

  getAccessStatus(): Observable<GetUserContextResponse> {
    return this.claimUserContext$;
  }
}
