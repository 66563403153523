<h2 mat-dialog-title>
  {{ 'claim-management.create_claim_for_equipment' | translate }}
</h2>
<div mat-dialog-content gaCategoryGroup="CreateClaim">
  <form (ngSubmit)="createClaim()">
    <mat-form-field
      class="full-width"
      gaClickListener="EquipmentNumberInputClick"
    >
      <mat-label>
        {{ 'claim-management.equipment_number' | translate }}</mat-label
      >
      <input matInput [formControl]="equipmentNumber" required />

      <mat-progress-spinner
        matTextSuffix
        [mode]="'indeterminate'"
        *ngIf="requestPending"
        [diameter]="15"
        matTooltip="{{
          'claim-management.claim_on_delivery.equipment_number_validation_pending'
            | translate
        }}"
      ></mat-progress-spinner>
      <mat-error *ngIf="errorKey">{{ errorKey | translate }}</mat-error>
    </mat-form-field>
  </form>
</div>
<div mat-dialog-actions gaCategoryGroup="CreateClaim">
  <button
    gaClickListener="CancelCreateClaim"
    mat-button
    mat-dialog-close
    type="button"
  >
    {{ 'claim-management.cancel' | translate }}
  </button>
  <button
    gaClickListener="ConfirmCreateClaim"
    mat-raised-button
    color="accent"
    (click)="createClaim()"
    [disabled]="!equipmentNumber.valid || requestPending"
  >
    {{ 'claim-management.create_claim' | translate }}
  </button>
</div>
