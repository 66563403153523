import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { TranslateModule } from '@ngx-translate/core';
import { AppLogPlattformPresetsComponent } from './app-log-platform-presets/app-log-platform-presets.component';
import { CalendarHeaderDesignSystemComponent } from './calendar-header/calendar-header-design-system.component';
import { DsPresetCalenderHeaderComponent } from './calendar-header/calendar-header.component';
import { ConnectedPresetsComponent } from './connected-presets/connected-presets.component';
import { ConnectedUtilizationPresetsComponent } from './connected-presets/connected-utilization-presets.component';
import { CustomRangePanelComponent } from './custom-range-panel/custom-range-panel.component';
import { ConnectedPresets12MonthsComponent } from './connected-presets/connected-presets-12-months.component';
import { ConnectedPresets6MonthsComponent } from './connected-presets/connected-presets-6-months.component';
@NgModule({
  imports: [
    CommonModule,
    MatButtonModule,
    MatCardModule,
    MatDatepickerModule,
    MatDividerModule,
    MatFormFieldModule,
    FlexLayoutModule,
    TranslateModule,
  ],
  declarations: [
    CustomRangePanelComponent,
    DsPresetCalenderHeaderComponent,
    AppLogPlattformPresetsComponent,
    ConnectedPresetsComponent,
    ConnectedPresets6MonthsComponent,
    ConnectedPresets12MonthsComponent,
    ConnectedUtilizationPresetsComponent,
    CalendarHeaderDesignSystemComponent,
  ],
  exports: [
    DsPresetCalenderHeaderComponent,
    CalendarHeaderDesignSystemComponent,
  ],
})
export class DsAdvancedDatepickerModule {}
