import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LocaleDatePipe } from './locale-date-pipe.pipe';

@NgModule({
  imports: [CommonModule],
  declarations: [LocaleDatePipe],
  exports: [LocaleDatePipe],
  providers: [LocaleDatePipe],
})
export class LocaleDatePipeModule {}
