import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { ClaimService, FileService } from '@claim-management-lib/data-access';
import { FileOptions } from '@design-system/cdk/file-drop';
import { DsComponentsAttachmentsModule } from '@design-system/components/attachments';
import {
  DsSnackbarService,
  DsSnackbarComponent,
  DsSnackbarType,
} from '@design-system/feature/snackbar';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { GuidUtils } from '@utils/guid-utils';
import { IsImagePipe } from '@utils/is-image-pipe';
import { finalize } from 'rxjs/operators';
import {
  AttachmentFile,
  attachmentsSupportedFiles,
} from '../../claim-management-service.model';
import { api_version } from '../../constants';
import { AttachmentProcessor } from '../../utils';

@Component({
  selector: 'cm-company-attachments',
  templateUrl: './company-attachments.component.html',
  styleUrls: ['./company-attachments.component.scss'],
  standalone: true,
  imports: [
    DsComponentsAttachmentsModule,
    MatIconModule,
    MatMenuModule,
    MatButtonModule,
    TranslateModule,
    CommonModule,
    IsImagePipe,
    DsSnackbarComponent,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CompanyAttachmentsComponent {
  @Input() claimId: number;
  @Input() attachments: AttachmentFile[] = [];

  documentOptions: FileOptions = {
    maxUploadSizeInMb: 200,
    supportedFilesTypes: attachmentsSupportedFiles,
  };

  uploadingFiles = false;
  attachmentsDirty = false;

  constructor(
    private fileService: FileService,
    private snackbar: DsSnackbarService,
    private translateService: TranslateService,
    private claimService: ClaimService,
    private cd: ChangeDetectorRef,
  ) {}

  get attachmentsToShow(): AttachmentFile[] {
    return this.attachments?.filter((a) => !a.deleted);
  }

  updateCompanyAttachments() {
    this.claimService
      .updateCompanyAttachments(api_version, {
        claim_id: this.claimId,
        company_attachments: AttachmentProcessor.MapToAttachment(
          this.attachments,
        ),
      })
      .pipe(
        finalize(() => {
          this.attachments.forEach((att) => {
            if (att.rotationAngle) {
              att.rotationAngle = 0;
              att.thumbnailUrl =
                (att.thumbnailUrl?.substr(
                  0,
                  att.thumbnailUrl.lastIndexOf('?'),
                ) || att.thumbnailUrl) + `?${new Date().getTime().toString()}`; // bust the cache to make it download the new thumb

              this.cd.detectChanges();
            }
          });
        }),
      )
      .subscribe({
        error: (error) => {
          this.snackbar.queue(
            error.error?.message ||
              this.translateService.instant(
                'claim-management.attachments.update_error',
              ),
            {
              type: DsSnackbarType.Error,
            },
          );
        },
      });
  }

  fileSelect(files: File[]) {
    this.uploadingFiles = true;

    files.forEach((file) => {
      // add to attachments list
      const attachedFile: AttachmentFile = {
        id: GuidUtils.newGuid(),
        name: file.name,
        size: file.size,
        progress: 0,
        url: '',
        attachment_type: AttachmentProcessor.GetAttachmentType(file.type),
        deleted: false,
      };

      this.attachments = [...this.attachments, attachedFile];
      this.fileService
        .fileUpload(file.name, api_version, file, 'events', true)
        .pipe(
          finalize(() => {
            if (this.attachments.every((x) => x.progress === 100)) {
              this.uploadingFiles = false;
              if (this.claimId) {
                this.updateCompanyAttachments();
              } else {
                this.attachmentsDirty = true;
              }
              this.cd.detectChanges();
            }
          }),
        )
        .subscribe({
          next: (event) => {
            AttachmentProcessor.ProcessUpload(attachedFile, event);
            this.cd.detectChanges();
          },
          error: (error) => {
            this.deleteFile(attachedFile);
            this.snackbar.queue(
              error.error?.message ||
                this.translateService.instant(
                  'claim-management.attachments.file_upload_error',
                ),
              {
                type: DsSnackbarType.Error,
              },
            );
          },
        });
    });
  }

  deleteFile(file: AttachmentFile) {
    file.deleted = true;
    if (this.claimId) {
      this.updateCompanyAttachments();
    } else {
      this.attachmentsDirty = true;
    }
  }

  rotateFile(file: AttachmentFile, angle: number) {
    if (this.claimId) {
      file.rotationAngle = angle;
      this.updateCompanyAttachments();
    } else {
      file.rotationAngle =
        (file.rotationAngle ? file.rotationAngle + angle : angle) % 360;
      this.attachmentsDirty = true;
    }
  }
}
