import { Component, Input } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'paldesk-dialog-prompt',
  templateUrl: './prompt.component.html',
})
export class PromptComponent {
  @Input()
  title: string;
  @Input()
  content: string;
  @Input()
  confirmText: string;
  @Input()
  cancelText: string;
  @Input()
  cancelbutton: boolean;
  @Input()
  list: string[];
  @Input()
  hideCancelbutton: boolean;

  constructor(public dialogRef: MatDialogRef<PromptComponent>) {}
}
