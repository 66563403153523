<h5>
  {{ 'claim-management.dashboard.' + name | translate }}:<br />
  {{ value | currency: currency : 'symbol' }}
</h5>
<hr />
{{ value / total | percent: '1.0-2' }}
<br />
<ng-container *ngIf="value / total < 1">
  <br />
  {{
    ('claim-management.dashboard.total' | translate) +
      ': ' +
      (total | currency: currency : 'symbol')
  }}
  <br />
</ng-container>
<br />
