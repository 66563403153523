<h1 mat-dialog-title>
  {{ 'claim-management.claim_history_log' | translate }}
</h1>

<mat-dialog-content>
  <ng-container *pdLet="historyLog$ | async as historyLog">
    <ds-table>
      <table mat-table *ngIf="!pending" [dataSource]="historyLog">
        <ng-container matColumnDef="modified_by">
          <th mat-header-cell *matHeaderCellDef>
            {{ 'claim-management.claim_history.modified_by' | translate }}
          </th>
          <td mat-cell *matCellDef="let row">
            {{ row.modified_by }}
          </td>
        </ng-container>
        <ng-container matColumnDef="modified_on">
          <th mat-header-cell *matHeaderCellDef>
            {{ 'claim-management.claim_history.modified_on' | translate }}
          </th>
          <td mat-cell *matCellDef="let row">
            {{ row.modified_on | date: 'short' }}
          </td>
        </ng-container>
        <ng-container matColumnDef="state_sp_to_ga">
          <th mat-header-cell *matHeaderCellDef>
            {{ 'claim-management.claim_history.state_sp_to_ga' | translate }}
          </th>
          <td mat-cell *matCellDef="let row">
            {{
              'claim-management.claim_state.' + row.claim_state
                | translate
                | titlecase
            }}
          </td>
        </ng-container>
        <ng-container matColumnDef="state_area_ga">
          <th mat-header-cell *matHeaderCellDef>
            {{ 'claim-management.claim_history.state_area_ga' | translate }}
          </th>
          <td mat-cell *matCellDef="let row">
            {{
              row.claim_state_area
                ? ('claim-management.claim_state.' + row.claim_state_area
                  | translate
                  | titlecase)
                : ('general.n_a' | translate)
            }}
          </td>
        </ng-container>
        <ng-container matColumnDef="decision_reason">
          <th mat-header-cell *matHeaderCellDef>
            {{ 'claim-management.claim_history.decision_reason' | translate }}
          </th>
          <td mat-cell *matCellDef="let row">
            {{ row.decision_reason || 'general.n_a' | translate }}
          </td>
        </ng-container>
        <ng-container matColumnDef="return_reason">
          <th mat-header-cell *matHeaderCellDef>
            {{ 'claim-management.claim_history.return_reason' | translate }}
          </th>
          <td mat-cell *matCellDef="let row">
            {{ row.return_reason || 'general.n_a' | translate }}
          </td>
        </ng-container>
        <ng-container matColumnDef="state">
          <th mat-header-cell *matHeaderCellDef>
            {{ 'claim-management.claim_history.state' | translate }}
          </th>
          <td mat-cell *matCellDef="let row">
            {{
              'claim-management.claim_state.' + row.claim_state
                | translate
                | titlecase
            }}
          </td>
        </ng-container>
        <ng-container matColumnDef="actions" stickyEnd>
          <th mat-header-cell *matHeaderCellDef>
            <ds-table-settings
              [settingsKey]="settingsKey"
              [defaultSettings]="columns"
              (userSettings)="setUserSetting($event)"
            />
          </th>
          <td class="mat-action-cell" mat-cell *matCellDef="let contract"></td>
        </ng-container>
        <tr
          mat-header-row
          *matHeaderRowDef="columns | displayColumns; sticky: true"
        ></tr>
        <tr
          mat-row
          *matRowDef="let row; columns: columns | displayColumns"
        ></tr>
      </table>
      <ds-table-loading
        *ngIf="pending"
        [numberOfHeaders]="4"
        [numberOfRows]="5"
      ></ds-table-loading>
    </ds-table>
  </ng-container>
</mat-dialog-content>

<mat-dialog-actions>
  <button [mat-dialog-close] mat-button>
    {{ 'general.close' | translate }}
  </button>
</mat-dialog-actions>
