/**
 * v1.1 - Claim Management API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1_claim_management
 * Contact: F.John@palfinger.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface StatusStatisticsItem { 
    
    display_state: StatusStatisticsItemDisplayState;
    
    claim_count: number;
}
export enum StatusStatisticsItemDisplayState {
    Draft = 'Draft',
    Sent = 'Sent',
    Returned = 'Returned',
    PartsRequested = 'PartsRequested',
    ReceivedFromSP = 'ReceivedFromSP',
    PartsRequestedFromSP = 'PartsRequestedFromSP',
    AcceptedFully = 'AcceptedFully',
    AcceptedPartially = 'AcceptedPartially',
    Declined = 'Declined',
    DecidedToSP = 'DecidedToSP',
    PartsSentFromSP = 'PartsSentFromSP',
    InformationRequestedFromSP = 'InformationRequestedFromSP',
    InformationGivenToGA = 'InformationGivenToGA'
};



