<h2 mat-dialog-title>
  {{ 'claim-management.dashboard.custom_periods' | translate }}
</h2>

<form [formGroup]="form" (ngSubmit)="save()">
  <mat-dialog-content>
    <div fxLayout="column" [fxLayoutGap]="1 | dsSpacing">
      <mat-form-field>
        <mat-label>{{
          'claim-management.dashboard.lower_boundary' | translate
        }}</mat-label>
        <input matInput type="number" formControlName="lowerBoundary" />
        <mat-error *ngIf="form.get('lowerBoundary')?.hasError('pattern')">
          {{ 'general.must_be_positive_number' | translate }}
        </mat-error>
      </mat-form-field>

      <mat-form-field>
        <mat-label>{{
          'claim-management.dashboard.higher_boundary' | translate
        }}</mat-label>
        <input matInput type="number" formControlName="higherBoundary" />
        <mat-error *ngIf="form.get('higherBoundary')?.hasError('pattern')">
          {{ 'general.must_be_positive_number' | translate }}
        </mat-error>
      </mat-form-field>

      <ds-text-indicator
        *ngIf="form.errors?.['invalidBoundaries']"
        type="error"
      >
        {{ 'claim-management.dashboard.invalid_boundaries' | translate }}
      </ds-text-indicator>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>
      {{ 'general.cancel' | translate }}
    </button>
    <button
      mat-raised-button
      color="primary"
      type="submit"
      [disabled]="!hasChanges || form.invalid || saving"
    >
      {{ 'general.save' | translate }}
    </button>
  </mat-dialog-actions>
</form>
