<ds-attachments
  [showFileSize]="false"
  [documentOptions]="documentOptions"
  [attachments]="attachmentsToShow"
  (fileSelect)="fileSelect($event)"
  [showPlaceholder]="false"
  [readOnlyMode]="false"
  [uploadButtonText]="'claim-management.attachments.upload_files' | translate"
  gaCategoryGroup="ClaimDecision"
>
  <ng-template let-file #actions>
    <div fxLayout="row">
      <button
        mat-icon-button
        [matMenuTriggerFor]="menu"
        [disabled]="uploadingFiles"
      >
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu #menu="matMenu" [xPosition]="'before'">
        <a
          gaClickListener="AttachmentsDeleteFile"
          mat-menu-item
          (click)="deleteFile(file)"
        >
          <mat-icon>delete</mat-icon>
          {{ 'claim-management.attachments.delete' | translate }}
        </a>
        <ng-container *ngIf="file.name | isImage">
          <a
            gaClickListener="AttachmentsRotateFileLeft"
            mat-menu-item
            (click)="rotateFile(file, -90)"
          >
            <mat-icon>rotate_left</mat-icon>
            {{ 'claim-management.attachments.rotate_left' | translate }}
          </a>
          <a
            gaClickListener="AttachmentsRotateFileRight"
            mat-menu-item
            (click)="rotateFile(file, 90)"
          >
            <mat-icon>rotate_right</mat-icon>
            {{ 'claim-management.attachments.rotate_right' | translate }}
          </a>
        </ng-container>
      </mat-menu>
    </div>
  </ng-template>
</ds-attachments>
