import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DashboardService } from '@claim-management-lib/data-access';
import { api_version } from '@claim-management-lib/feat-claim-shared';
import {
  DsSnackbarService,
  DsSnackbarType,
} from '@design-system/feature/snackbar';
import { TranslateService } from '@ngx-translate/core';
import { finalize } from 'rxjs';

export interface CustomPeriodsDialogData {
  lowerBoundary: number;
  higherBoundary: number;
}

@Component({
  selector: 'cm-custom-periods-dialog',
  templateUrl: './custom-periods-dialog-component.component.html',
  styleUrl: './custom-periods-dialog-component.component.scss',
})
export class CustomPeriodsDialogComponent {
  form: FormGroup;
  saving = false;
  initialValues: CustomPeriodsDialogData;

  constructor(
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<CustomPeriodsDialogComponent>,
    private dashboardService: DashboardService,
    private snackbar: DsSnackbarService,
    private translateService: TranslateService,
    @Inject(MAT_DIALOG_DATA) private data: CustomPeriodsDialogData,
  ) {
    this.initialValues = { ...data };
    this.form = this.fb.group(
      {
        lowerBoundary: [
          this.data.lowerBoundary,
          [
            Validators.required,
            Validators.min(0),
            Validators.max(9999),
            Validators.pattern(/^\d+$/),
          ],
        ],
        higherBoundary: [
          this.data.higherBoundary,
          [
            Validators.required,
            Validators.min(0),
            Validators.max(9999),
            Validators.pattern(/^\d+$/),
          ],
        ],
      },
      { validators: this.boundaryValidator },
    );
  }

  private boundaryValidator(group: FormGroup) {
    const lower = group.get('lowerBoundary')?.value;
    const higher = group.get('higherBoundary')?.value;

    if (lower && higher && lower >= higher) {
      return { invalidBoundaries: true };
    }
    return null;
  }

  get hasChanges(): boolean {
    return (
      this.form.value.lowerBoundary !== this.initialValues.lowerBoundary ||
      this.form.value.higherBoundary !== this.initialValues.higherBoundary
    );
  }

  save(): void {
    if (this.form.valid) {
      this.saving = true;
      this.dashboardService
        .setDashboardCustomPeriods(api_version, {
          lower_boundary: this.form.value.lowerBoundary,
          higher_boundary: this.form.value.higherBoundary,
        })
        .pipe(finalize(() => (this.saving = false)))
        .subscribe({
          next: () => {
            this.snackbar.queue(
              this.translateService.instant(
                'claim-management.dashboard.custom_periods_saved',
              ),
              { type: DsSnackbarType.Success },
            );
            this.dialogRef.close(true);
          },
          error: () => {
            this.snackbar.queue(
              this.translateService.instant('general.error_code.error'),
              { type: DsSnackbarType.Error },
            );
          },
        });
    }
  }
}
