import { Injectable } from '@angular/core';
import {
  DisabledPositionType,
  TableSettings,
  TableSettingsService,
} from '@design-system/components/table-settings';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AccessService } from './access.service';

export enum PalfingerColumns {
  equipmentNumber = 'equipmentNumber',
  repairDate = 'repairDate',
  claimNumber = 'claimNumber',
  claimNumberSP = 'claim_number_sp',
  claimNumberArea = 'claim_number_area',
  claimType = 'claimType',
  state = 'state',
  stateSPtoGA = 'state_sp_to_ga',
  stateAreaGA = 'state_area_ga',
  productType = 'productType',
  createdOn = 'createdOn',
  servicePartnerName = 'service_partner_name',
  totalSP = 'requested_sp',
  totalApprovedGA = 'accepted_sp',
  totalGA = 'requested_from_area',
  totalApprovedArea = 'accepted_from_area',
  totalApproved = 'approvedSum',
  totalRequested = 'requestedSum',
  deliveryNote = 'deliveryNoteNumber',
  decisionDate = 'decisionDate',
  decisionDateArea = 'decisionDateArea',
  licensePlate = 'licensePlate',
  requestedProcessingFeeGA = 'requested_processing_fee_ga',
  requestedProcessingFeeSP = 'requested_processing_fee_sp',
  approvedProcessingFeeArea = 'approved_processing_fee_area',
  approvedProcessingFeeGA = 'approved_processing_fee_ga',
  approvedProcessingFeeStateArea = 'approved_processing_fee_state_area',
  approvedProcessingFeeStateGA = 'approved_processing_fee_state_ga',
  creditNotes = 'credit_notes',
  internalNote = 'internal_note',
  creditNotesArea = 'credit_notes_area',
  submittedToGaOn = 'submitted_to_ga_on',
}

@Injectable({
  providedIn: 'root',
})
export class DisplayColumnsSettingsService {
  palfingerColumns = PalfingerColumns;
  palfingerColumnsEntries = Object.entries(PalfingerColumns);
  displayColumns: TableSettings[];

  decisionGADisplayedColumns: TableSettings[] = this.mapToTableSettings([
    PalfingerColumns.equipmentNumber,
    PalfingerColumns.deliveryNote,
    PalfingerColumns.productType,
    PalfingerColumns.createdOn,
    PalfingerColumns.repairDate,
    PalfingerColumns.decisionDate,
    PalfingerColumns.decisionDateArea,
    PalfingerColumns.claimNumber,
    PalfingerColumns.claimNumberSP,
    PalfingerColumns.claimNumberArea,
    PalfingerColumns.stateSPtoGA,
    PalfingerColumns.stateAreaGA,
    PalfingerColumns.servicePartnerName,
    PalfingerColumns.totalSP,
    PalfingerColumns.totalApprovedGA,
    PalfingerColumns.totalGA,
    PalfingerColumns.totalApprovedArea,
    PalfingerColumns.licensePlate,
    PalfingerColumns.requestedProcessingFeeGA,
    PalfingerColumns.requestedProcessingFeeSP,
    PalfingerColumns.approvedProcessingFeeArea,
    PalfingerColumns.approvedProcessingFeeGA,
    PalfingerColumns.approvedProcessingFeeStateArea,
    PalfingerColumns.approvedProcessingFeeStateGA,
    PalfingerColumns.creditNotes,
    PalfingerColumns.creditNotesArea,
    PalfingerColumns.internalNote,
    PalfingerColumns.claimType,
    PalfingerColumns.submittedToGaOn,
    'actions',
  ]);
  nonDecisionDisplayedColumns: TableSettings[] = this.mapToTableSettings([
    PalfingerColumns.equipmentNumber,
    PalfingerColumns.productType,
    PalfingerColumns.createdOn,
    PalfingerColumns.repairDate,
    PalfingerColumns.decisionDate,
    PalfingerColumns.claimNumber,
    PalfingerColumns.claimNumberArea,
    PalfingerColumns.state,
    PalfingerColumns.totalRequested,
    PalfingerColumns.totalApproved,
    PalfingerColumns.licensePlate,
    PalfingerColumns.creditNotes,
    PalfingerColumns.internalNote,
    PalfingerColumns.claimType,
    'actions',
  ]);
  decisionSPDisplayedColumns: TableSettings[] = this.mapToTableSettings([
    PalfingerColumns.equipmentNumber,
    PalfingerColumns.deliveryNote,
    PalfingerColumns.productType,
    PalfingerColumns.createdOn,
    PalfingerColumns.repairDate,
    PalfingerColumns.decisionDate,
    PalfingerColumns.claimNumber,
    PalfingerColumns.state,
    PalfingerColumns.totalRequested,
    PalfingerColumns.totalApproved,
    PalfingerColumns.licensePlate,
    PalfingerColumns.creditNotes,
    PalfingerColumns.internalNote,
    PalfingerColumns.claimType,
    'actions',
  ]);

  constructor(
    private accessService: AccessService,
    private tableSettingsService: TableSettingsService,
  ) {}

  mapToTableSettings(columns: string[]): TableSettings[] {
    return columns.map((column) => {
      const res: TableSettings = {
        name: column,
        selected: true,
      };
      if (column === 'actions') res.disabledPosition = DisabledPositionType.End;
      if (column === PalfingerColumns.claimNumber)
        res.disabledPosition = DisabledPositionType.Keep;

      return res;
    });
  }

  setDisplayColumns(): Observable<any> {
    this.accessService.getAccessStatus().subscribe({
      next: (userContext) => {
        if (userContext.is_decision_general_agent) {
          this.displayColumns = this.decisionGADisplayedColumns;
        } else if (
          userContext.is_non_decision_service_partner ||
          userContext.is_non_decision_general_agent
        ) {
          this.displayColumns = this.nonDecisionDisplayedColumns;
        } else {
          this.displayColumns = this.decisionSPDisplayedColumns;
        }
        if (!userContext.claim_on_delivery_access) {
          this.displayColumns = this.displayColumns.filter(
            (x) => x.name !== PalfingerColumns.deliveryNote,
          );
        }
      },
    });
    return this.getUserColumnsSettings();
  }

  getUserColumnsSettings(): Observable<any> {
    return this.tableSettingsService
      .get('claim-management.claimdesk.table_settings.v2', this.displayColumns)
      .pipe(
        tap((storedUserColumns) => {
          if (storedUserColumns?.length)
            this.displayColumns = storedUserColumns;
        }),
      );
  }
}
