import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, Routes } from '@angular/router';
import { DashboardComponent } from '@claim-management-lib/feat-dashboard';
import { APPS } from '@config';
import { AuthGuard } from '@features/auth';
import { AppGuard } from './app.guard';

export const appRoutes: Routes = [
  {
    path: '',
    canActivate: [
      (next: ActivatedRouteSnapshot) => inject(AuthGuard).canActivate(next),
    ],
    data: {
      permissions: {
        roles: [
          APPS.ECLAIM.ROLES.ADMIN,
          APPS.ECLAIM.ROLES.USER,
          APPS.ECLAIM.ROLES.DEALER,
          APPS.ECLAIM.ROLES.RECALL_ADMIN,
        ],
      },
    },
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'dashboard',
      },
      {
        path: 'link/:id',
        children: [],
      },
      {
        path: 'dashboard',
        component: DashboardComponent, // NOTE: Lazy loading not possible since NotificationSettingsComponent from the Dashboard module is used in the app.ts. Second option to keep lazy loading would be to move the NotificationSettingsComponent to the claim-management app.
        data: {
          breadcrumb: 'Dashboard',
        },
        canActivate: [AppGuard],
      },
      {
        path: 'claimdesk',
        loadChildren: () =>
          import('@claim-management-lib/feat-claimdesk').then(
            (m) => m.ClaimdeskModule,
          ),
        data: {
          breadcrumb: 'Claimdesk',
        },
        canActivate: [AppGuard],
      },
      {
        path: 'maintenance',
        loadChildren: () =>
          import('@claim-management-lib/feat-maintenance').then(
            (m) => m.MaintenanceModule,
          ),
        data: {
          breadcrumb: 'Maintenance',
        },
        canActivate: [AppGuard],
      },
      {
        path: 'delivery',
        loadChildren: () =>
          import('@claim-management-lib/feat-claim-on-delivery').then(
            (m) => m.ClaimOnDeliveryModule,
          ),
        data: {
          breadcrumb: 'Claim on Delivery',
        },
        canActivate: [AppGuard],
      },
      {
        path: 'decision',
        loadChildren: () =>
          import('@claim-management-lib/feat-claim-decision').then(
            (m) => m.ClaimDecisionModule,
          ),
        data: {
          breadcrumb: 'Claim Decision',
        },
        canActivate: [AppGuard],
      },
      {
        path: 'claim',
        loadChildren: () =>
          import('@claim-management-lib/feat-claim-edit').then(
            (m) => m.ClaimEditModule,
          ),
        canActivate: [AppGuard],
      },
      {
        path: 'help',
        loadChildren: () =>
          import('@paldesk/design-system/feature/help-page').then(
            (m) => m.DSHelpPageModule,
          ),
        data: {
          breadcrumb: 'general.help',
        },
      },
      {
        path: 'ram',
        loadChildren: () =>
          import('@claim-management-lib/feat-recall-action-management').then(
            (m) => m.RecallActionManagementModule,
          ),
        data: {
          breadcrumb: 'Recall Management',
        },
      },
    ],
  },
  {
    path: 'callback',
    children: [],
  },
  {
    path: 'special',
    loadChildren: () =>
      import('@design-system/feature/special-pages').then(
        (m) => m.DsSpecialPagesModule,
      ),
  },
  {
    path: '**',
    redirectTo: 'special',
  },
];
